<template>
    <div
        :class="['tab-pill', {'active' : active, 'dark': darkScheme} ]">
        <div :class="['tab-pill-content', `icon-${iconPosition}`]">
            <div v-if="icon" class="picto">
                <icon :icon-name="icon"/>
            </div>
            <div v-if="label" class="tab-pill-text">
                {{ label }}
            </div>
            <slot name="indicator"></slot>
        </div>
    </div>
</template>

<script>

import Icon from "../Icon.vue";

export default {
    name: 'tab-item',
    components: {Icon},
    props: {
        active: {
            type: Boolean,
            default: false
        },
        darkScheme: {
            type: Boolean,
            default: false
        },
        icon: {
            type: String,
            default: null
        },
        iconPosition: {
            type: String,
            default: 'left' //  left or top
        },
        label: {
            type: String,
            default: null
        }
    },
}
</script>

<style lang="scss" scoped>

.tab-pill {

    --tab-text: var(--asphalt60);
    --tab-text-hover: var(--asphalt100);

    &.dark {
        --tab-text: var(--asphalt40);
        --tab-text-hover: var(--asphalt10);
    }


    cursor: pointer;
    font: var(--editor-ui-b2-regular);
    color: var(--tab-text);
    letter-spacing: 0.14px;

    .tab-pill-content {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 12px 0;
        gap: 4px;

        .picto {
            display: flex;
            //margin-right: 4px;
            //padding: 0 4px 0 0;
        }

        .tab-pill-text {
            //padding-bottom: 12px;
        }

        &.icon-top {
            flex-direction: column;
            .picto {
                padding: 12px 0 0;
                //margin-bottom: 4px;
                //margin-right: 0;
            }
        }
    }


    &.active {
        color: var(--tab-text-hover);
        font: var(--editor-ui-b2-semibold);

        &:after {
            content: "";
            width: 100%;
            height: 2px;
            display: block;
            border-radius: 2px 2px 0 0;
            background: var(--tab-text-hover);
        }
    }

    &:hover {
        color: var(--tab-text-hover);
    }

    svg {
        width: 20px;
        height: 20px;
    }
}
</style>
