<template>
    <div :ref="'comment' + index" :class="['comment', {edit:edit, resolved:isResolved}]" @mouseenter="hovering = true" @mouseleave="hovering = false">
        <img class="profile-picture" alt="userimage" v-if="user.image && user.image.url" :src="user.image.url"/>
        <UserImagePlaceholder :name="user.name" :size="'small'" v-else />
        <div class="comment-details">
            <div class="comment-user-and-text">
                <div class="comment-user">
                    <div class="name">{{ user.name }}</div>
                    <div class="time">{{ DateTimeHelper.getMoment(comment.createdAt) }}</div>
                </div>
                <div class="comment-text">{{ comment.text }}</div>
            </div>
            <div v-if="comment.label" :class="['label', comment.label.toLowerCase()]">{{ comment.label }}</div>
        </div>
        <MiniFab class="resolve-check" v-if="isReceiverMode && resolveComment" :icon-name="isResolved ? 'history' : 'check'" :handle-click="handleCommentResolve"/>
        <div class="edit-container-wrapper" v-if="edit">
            <MiniFab :icon-name="'edit'" :handle-click="updateComment"/>
            <MiniFab :type="'error'" :icon-name="'delete'" :handle-click="deleteComment" />
        </div>
    </div>
</template>

<script>
    import Icon from "../../../common/Icon.vue";
    import UserImagePlaceholder from "../../../common/components/UserImagePlaceholder.vue";
    import MiniFab from "@/scripts/common/components/MiniFab.vue";
    import ApiHelper from "@/scripts/api/ApiHelper.js";

    export default {
        name: "CommentComponent",
        props:['comment', 'resolveComment', 'updateComment', 'deleteComment', 'edit', 'index'],
        components: {
            MiniFab,
            UserImagePlaceholder,
            Icon
        },
        data() {
            return {
                show:false,
                hovering:false
            }
        },
        methods: {
            handleCommentResolve() {
                if(this.resolveComment) {
                    this.resolveComment();
                }
            },
            async saveReview() {
                try {
                    await ApiHelper.post('/api/review/' + this.$reviewStore.review._id + "/" + this.$reviewStore.review.contentId, this.$reviewStore.review);
                    this.saved = true;
                    this.saving = false;
                }
                catch(err) {
                    console.log('Failed to save review', err)
                }
            },
        },
        computed: {
            //We have to check who wrote the comment, basically just owner or not.
            //The owner only valid if we are checking followups but this is a more general approach
            user() {
                let owner = this.$reviewStore.receiver ? this.$store.state.user : this.$store.state.view.user

                if(this.comment.fromUserId) {
                    //We have a logged in user
                    if(owner && this.comment.fromUserId === owner._id) {
                        return {
                            name: owner.name,
                            image: owner.image
                        }
                    //If not owner find the reviewer in the reviews
                    }else {
                        let reviewers = this.$reviewStore.reviews.map(x => x.reviewerDetails);
                        let foundReviewer =  reviewers.find(reviewer => reviewer._id === this.comment.fromUserId);
                        if(foundReviewer) {
                            return foundReviewer
                        }
                        //Edge case when the comment component is viewed from the admin panel, this is always true in that case
                        else {
                            return this.$reviewStore.review.reviewerDetails
                        }
                    }
                }
                else {
                    return this.$reviewStore.review.reviewerDetails
                }
            },
            isReceiverMode() {
                return this.$reviewStore.receiver;
            },
            isResolved() {
                return this.comment.resolved
            }
        }
    }
</script>

<style scoped lang="scss">
    .comment {
        display: flex;
        padding: 20px;
        align-items: flex-start;
        gap: 12px;
        align-self: stretch;
        position: relative;

        &.resolved {
            .profile-picture, .placeholder-image, .comment-details {
                opacity: 60%;
            }
        }
        .profile-picture {
            width: 36px;
            height: 36px;
            border-radius: 50%;
        }

        &:hover {
            background-color: var(--asphalt90);
        }

        &:hover {
            .resolve-check {
                opacity: 1;
            }
            .resolved-icon {
                opacity: 0;
            }
        }

        .resolve-check, .resolved-icon {
            position: absolute;
            right: 20px;
            top: 19px;
        }

        .resolve-check {
            opacity: 0;
        }

        .edit-wrapper {
            display: flex;
            width: 28px;
            height: 28px;
            padding: 4px;
            justify-content: center;
            align-items: center;
            border-radius: 4px;
        }

        .resolved-icon {
            opacity: 1;

            .icon-wrapper {
                display: flex;
                width: 28px;
                height: 28px;
                padding: 4px;
                justify-content: center;
                align-items: center;
                border-radius: 4px;
                svg {
                    width: 20px;
                    height: 20px;
                    flex-shrink: 0;
                    color: var(--asphalt10);
                }
            }
        }

        .edit-container-wrapper {
            position: absolute;
            right: 17px;
            top: 15px;
            display: flex;
            gap: 4px;
        }

        .comment-details {

            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 16px;

            .comment-user-and-text {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                gap: 8px;
                align-self: stretch;

                .comment-user {
                    display: flex;
                    align-items: center;
                    gap: 8px;

                    .name {
                        font: var(--editor-ui-b2-semibold);
                        color: var(--asphalt20);
                    }
                    .time {
                        font: var(--editor-ui-b3-regular);
                        color: var(--asphalt40);
                    }
                }
                .comment-text {
                    font: var(--editor-ui-b2-regular);
                    color: var(--asphalt20);
                    overflow-wrap: anywhere;
                    max-width: 300px;
                }
            }

            .label {
                padding: 4px 12px;
                font: var(--editor-ui-l-medium);
                color: var(--asphalt40);
                border-radius: 30px;
                border: 1px solid var(--asphalt80);

                &.design {
                    background-color: #362B3B;
                    color:#E6BEF9;
                }

                &.structure {
                    background-color: #373B2B;
                    color:#E3F9A6;
                }

                &.content {
                    background-color: #2B3A3B;
                    color:#B9F3F7;
                }

                &.imagery {
                    background-color: #3B302B;
                    color:#FECAA4;
                }
            }
        }

    }
</style>