<template v-if="portfolio !== null && templateSettings !== null">
    <!-- Old layout -->
    <div v-bind="$attrs" v-if="!templateSettings.theme || templateSettings.theme.includes('profile_')"
         :class="['ufo--portfolio-page', 'profile-page', templateSettings.theme, 'profile--hp', (templateSettings.theme === 'profile_theme_default' && portfolio.about) ? 'default-template' : '']"
         :style="[getTextStyles]"
    >
        <div :class="[
            'grid',
            'ufo--page-hero',
            templateSettings.theme,
            templateSettings.navigation && templateSettings.navigation.fill === true ? 'filled' : 'transparent']"
        >
            <div :class="['ufo--page-hero-content',
                        templateSettings.theme === 'theme_one' ||
                        templateSettings.theme === 'theme_seven' ||
                        templateSettings.theme === 'theme_eight' ? 'no-profile-picture' :' has-picture',
                        contentPosition]"
            >
                <PortfolioHeroHelper />
            </div>
        </div>
        <div class="ufo--page-content ufo--home-page-content">
            <GetHeroNavigation v-if="templateSettings.theme === 'profile_theme_default'" :show-home-button="templateSettings.theme === 'profile_theme_default'" />
            <div v-if="templateSettings.theme === 'theme_two'" class="grid">
                <div :class="'works_line_' + templateSettings.theme"></div>
            </div>
            <div class="ufo--page-sections">
                <div class="ufo-sec ufo-sec--projectgrid">
                    <div class="grid ufo--user-projects">
                        <div v-bind:key="project" v-for="project in projects"
                             :class="['project',
                                        'title-is--' + templateSettings.projectTitle.visibility,
                                        'title-is--' + templateSettings.projectTitle.placement,
                                        'title-is--' + templateSettings.projectTitle.color,
                                        'thumbnail-is--' + templateSettings.tileDisplayMode.split('-')[0],
                                        'thumbnail-is--' + templateSettings.tileDisplayMode,
                                        templateSettings.projectTitle.alignment ? 'title-align--' + templateSettings.projectTitle.alignment.vertical : '',
                                        templateSettings.projectTitle.alignment ? 'title-justify--' + templateSettings.projectTitle.alignment.horizontal : '',
                                        'layout-is--' + layout]"
                        >
                            <div class="project-name">
                                <div class="project-title">{{ project.title }}</div>
                                <div class="project-subtitle">{{ project.subtitle }}</div>
                            </div>
                            <div class="image-container">
                                <img :src="project.thumbnail" alt="" class="project-thumbnail">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <PublicPageFooter />
    </div>
    <!-- New Layout -->
    <div v-else v-bind="$attrs" :class="['ufo--portfolio-page', templateSettings.theme]" :style="[getTextStyles]">
        <PortfolioHeroHelper />
        <div class="ufo--page-content ufo--home-page-content">
            <div class="ufo--page-sections">
                <div class="projects-list project-wrapper">
                    <div v-if="showDividerLineSection" id="section-0" class="ufo-sec ufo-sec--dividerline">
                        <div class="grid">
                            <div class="divider-line weight-light below left">
                                <div class="text preview">
                                    <div class="ufo--section-text-container h2">
                                        Featured projects
                                    </div>
                                </div>
                                <div class="line preview" style="background-color: #33312f"></div>
                            </div>
                        </div>
                    </div>
                    <div class="ufo-sec ufo-sec--projectgrid" :style="[templateSettings.theme === 'theme_five' ? 'paddingTop: 0; margin-top: -100px' : null]">
                        <div class="grid ufo--user-projects">
                            <div v-bind:key="project" v-for="project in projects"
                                 :class="['project',
                                        'title-is--' + templateSettings.projectTitle.visibility,
                                        'title-is--' + templateSettings.projectTitle.placement,
                                        'title-is--' + templateSettings.projectTitle.color,
                                        'thumbnail-is--' + templateSettings.tileDisplayMode.split('-')[0],
                                        'thumbnail-is--' + templateSettings.tileDisplayMode,
                                        templateSettings.projectTitle.alignment ? 'title-align--' + templateSettings.projectTitle.alignment.vertical : '',
                                        templateSettings.projectTitle.alignment ? 'title-justify--' + templateSettings.projectTitle.alignment.horizontal : '',
                                        'layout-is--' + layout]"
                            >
                                <div class="project-name">
                                    <div class="project-title">{{ project.title }}</div>
                                    <div class="project-subtitle">{{ project.subtitle }}</div>
                                </div>
                                <div class="image-container">
                                    <img :src="project.thumbnail" alt="" class="project-thumbnail">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <PublicPageFooter />
</template>

<script>
import PortfolioHeroHelper from "../../portfolio/themes/_helper/PortfolioHeroHelper.vue";
import PublicPageFooter from "../../portfolio/common/PublicPageFooter.vue";
import GetHeroNavigation from "../../portfolio/common/hero/GetHeroNavigation.vue";

export default {
    name: "TemplatePreviewHomePage",
    props:['portfolio', 'projects'],
    components: {
        GetHeroNavigation,
        PortfolioHeroHelper,
        PublicPageFooter
    },
    mounted() {
        this.$store.state.view.activePage = 'home';
    },
    computed: {
        templateSettings() {
            return this.portfolio.templateSettings
        },
        layout() {
            return this.portfolio.templateSettings.thumbnailLayout ? this.portfolio.templateSettings.thumbnailLayout : this.portfolio.templateSettings.virtualThumbnailLayout
        },
        getTextStyles() {
            return this.$store.getters.getAllTextStylesInCss;
        },
        showDividerLineSection() {
            const dividerAllowedIn = ['theme_two', 'theme_sixteen']
            return dividerAllowedIn.includes(this.templateSettings.theme);
        },
        contentPosition() {
            return this.templateSettings.theme === "profile_theme_nine"
                ? `align-content-to-${this.templateSettings.hero.alignment.vertical}`
                : null
        }
    }
}
</script>

<style lang="scss" scoped>
    .profile_theme_default {
        .ufo-sec {
            --section-padding-top: 40px;
            --section-padding-bottom: 40px;
        }
    }
    .theme_one .ufo--user-projects .project .project-name .project-subtitle {
        @media (min-width: 768px) {
            max-width: 45%;
        }
    }
</style>