<template>
    <div class="large-tooltip-container" :class="{video:video}">
        <div class="video-wrapper" v-if="video">
            <video width="270"
                   height="150"
                   autoplay
                   loop
                   muted
                   preload="auto"
            >
                <source :src="video" type="video/webm">
            </video>
        </div>
        <div class="text-wrapper">
            <div class="title-text">{{ title }}</div>
            <div class="subtitle-text">{{ subtitle }}</div>
        </div>
        <Icon class="arrow" :class="[arrowPos]" :icon-name="'tooltip-arrow'" />
    </div>
</template>

<script>
import Icon from "../Icon.vue";
export default {
    name: "LargeTooltip",
    components: {
        Icon
    },
    props: ['title', 'subtitle', 'video', 'arrowPos']
}
</script>

<style scoped lang="scss">
    .large-tooltip-container {
        position: absolute;
        display: flex;
        width: 296px;
        flex-direction: column;
        align-items: flex-start;
        border-radius: 10px;
        background: var(--asphalt90);
        /* Dark/Large */
        box-shadow: 0 8px 24px 0 rgba(0, 0, 0, 0.40), 0 32px 40px 0 rgba(0, 0, 0, 0.32);
        outline: 1px solid var(--asphalt70);

        right: 70px;
        top: -3px;

        transition-property: opacity;
        transition-duration: 200ms;
        transition-timing-function: cubic-bezier(0.22,0.61,0.36,1);
        z-index: 10;

        &.video {
            width: 286px;
        }

        .relative-wrapper {
            position: relative;
        }

        .video-wrapper {
            padding: 8px 8px 0px 8px;


        }

        .text-wrapper {
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 12px;
            align-self: stretch;
            text-align: center;
            padding: 20px;

            .title-text {
                color: var(--asphalt10);
                font: var(--editor-ui-h3);
            }

            .subtitle-text {
                color: var(--asphalt30);
                font: var(--editor-ui-b2-regular);
            }
        }

        .arrow {
            position: absolute;

            &.top {
                left: 40px;
                rotate: 90deg;
                top: -21px;

                &.top-right {
                    left: unset;
                    right: 30px;
                }

            }

            &.right {
                right: -12px;
                top: 15px;
                rotate: 180deg;
            }

            &.left {
                left: -12px;
                top: 15px;
            }
        }

        &.video {
            .arrow {
                &.right {
                    right: -15px;
                }
            }
        }
    }

    .ufo-fab--container {
        top: calc(v-bind(templateHeroTopPos) - 10px);
        transform: translateX(0);
        opacity: 0;
        pointer-events: none;
        transition-property: opacity;
        transition-duration: 200ms;
        transition-timing-function: cubic-bezier(0.22,0.61,0.36,1);
        &.selected {
            opacity: 1;
        }
    }
</style>