<template>
    <a
        :href="socialLink.url"
        target="_blank"
        rel="nofollow noopener"
        :class="[socialLinkStyle, linkIsTextType ? 'text' : 'icon', getCleanType(socialLink.type).toLowerCase()]"
        :data-text="linkIsTextType ? getCleanType(socialLink.type) : null"
    >
        <span v-if="linkIsTextType">{{ getCleanType(socialLink.type) }}</span>
        <i v-else :class="['fa', 'fa-' + socialLink.type.toLowerCase()]"></i>
    </a>
</template>

<script>
import {SocialLinkTypeEnum} from "@/scripts/common/enums.js";

export default {
    name: "GetHeroSocialLinkItem",
    props: {
        socialLink: {
            type: Object,
            default: null
        }
    },
    methods: {
        getCleanType(linkType) {
            if(linkType.toLowerCase() === "x-twitter") return 'x';
            return this.templateSettings.navigation.navigationItems.button.upperCase
                ? linkType.toUpperCase()
                : linkType
        }
    },
    computed: {
        templateSettings() {
            return this.$store.state.view.portfolio.templateSettings
        },
        socialLinkStyle() {
            return this.templateSettings.navigation.navigationItems.socialLink.style
        },
        linkIsTextType() {
            return this.templateSettings.navigation.navigationItems.socialLink.type === SocialLinkTypeEnum.TEXT && this.templateSettings.theme !== "theme_seventeen"
        },
    }
}
</script>

<style lang="scss" scoped>
@import "@/styles/layouts/new-portfolio-layout/components/social-links";
</style>