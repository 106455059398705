<template>
    <img
        v-if="section.media.type === 'Image'"
        ref="img"
        @load="imageLoaded ? imageLoaded($refs.img) : null"
        loading="lazy"
        :class="forcedAspectRatio ? `aspect-ratio-${section.mediaAspectRatio}` : null"
            :src="getImageUrl"
        :data-zoom="setZoom"
        :data-zoomable="setZoom"
        :alt="section.media.altTag ? altTag : defaultCaption"
        :width="section.media.width"
        :height="section.media.height"
    />

    <div v-else-if="section.media.type === 'Video'" :class="['embed', 'embed-type-' + section.media.type.toLowerCase(),  'noHeightValue']">
        <iframe v-if="section.media.url" :src="section.media.url" loading="lazy" allowfullscreen="false" frameborder="0"></iframe>
    </div>
    <!-- TODO: container issue -->
    <template v-else-if="section.media.type === 'Prototype' || section.media.type === 'Media'">
        <div v-if="section.media.height" :class="['embed', 'embed-type-' + section.media.type.toLowerCase()]">
            <iframe v-if="section.media.url" :src="section.media.url" loading="lazy" :height="section.media.height" :width="'100%'" frameborder="0" allowTransparency="true" allowfullscreen="false"></iframe>
        </div>
        <div v-else :class="['embed', 'embed-type-' + section.media.type.toLowerCase(), 'noWidthValue', 'noHeightValue']">
            <iframe v-if="section.media.url" :src="section.media.url" loading="lazy" allowTransparency="true" allowfullscreen="false" frameborder="0"></iframe>
        </div>
    </template>
</template>

<script>
    export default {
        name: "PlaceMediaContent",
        props:['section', 'defaultCaption', 'placeholderImage'],
        computed: {
            setZoom() {
                return this.section.type !== 'MainHeader'
                    && (!this.section.media.displayMode || this.section.media.displayMode && this.section.media.displayMode !== 'full')
                    && (!this.section.media.frame || this.section.media.frame && this.section.media.frame === 'no-mockup')
            },
            altTag() {
                return this.section.media.altTag.replace('\"', '&quot;')
            },
            getImageUrl() {
                if(!this.section.media.croppedImageUrl && this.section.media.url?.includes('/example')) {
                    if (this.section.media.url.includes('suggestion-wireframes')) {
                        if(this.section.media.frame?.includes('desktop') || this.section.media.frame?.includes('monitor')) {
                            return '/example_project_images/v2/suggestion-wireframes-desktop.png'
                        }
                    } else if (this.section.media.url.includes('gallery-sample-tall')) {
                        if(this.section.media.frame?.includes('desktop') || this.section.media.frame?.includes('monitor')) {
                            return this.section.media.url.replace('tall', 'desktop').replace('jpg', 'png')
                        } else if (this.section.media.frame?.includes('tablet')) {
                            return  this.section.media.url.replace('tall', 'tablet').replace('jpg', 'png')
                        }
                    }
                    return this.section.media.url;
                }
                else {
                    return !!this.section.media.croppedImageUrl ? this.section.media.croppedImageUrl : this.section.media.url
                }
            },
        },
        methods: {
            imageLoaded(img) {
                if (this.setZoom && !!img && !!img.dataset.zoomable && img.naturalWidth > img.width) {
                    this.$store.state.zoom.attach(img);
                } else {
                    this.$store.state.zoom.detach(img);
                }
                this.$store.commit("recalculateScrollTriggers");
            },
            forcedAspectRatio() {
                return !!this.section?.mediaAspectRatio
            }
        }
    }
</script>