<!-- This component is very similar to ReviewFabs, but only for displaying data
     There were to many different branches for the logic, so for more readability I moved this out
     Loaded for Reciever mode (Owner checks reviews) and Submitted mode (Reviewer checks their review, gives followup but they can't edit)
 -->
<template>
    <div :id="section._id" v-if="$reviewStore.displayUI" :class="['review-fabs', section.type, {'sticky-header': isThereStickyHeader}]" :style="{zIndex:invertedIndex}">
        <div class="sticky">
            <div v-if="Object.values(sectionReactions).length" ref="reaction" class="reaction-fab-container">
                <fab :key="key" v-for="(value, key) in sectionReactions" :icon="null" :tooltip="getTooltip(key, value.reviewers)" :tooltip-position="'top'" >
                    <template #reaction>
                        <div class="reaction-in-fab-container">
                            <div class="reaction-wrapper">
                                <img :src="getReactionImage(key)" alt="reaction"/>
                            </div>
                            <span v-if="value.number > 1">{{value.number}}</span>
                        </div>
                    </template>
                </fab>
            </div>
            <div ref="comments" v-if="sectionComments.length" class="comments-fab-container">
                <fab :icon="null" @click="showComments" tooltip="Comments" :tooltip-position="'top'">
                    <template #comment>
                        <div class="comment-in-fab">
                            <Icon :icon-name="'chat'" />
                            <span>{{sectionComments.length}}</span>
                        </div>
                    </template>
                </fab>
                <div class="comments-container" v-if="comments">
                    <div class="header-row">
                        <div>Comments</div>
                        <Icon iconName="close" @click="showComments"/>
                    </div>
                    <div ref="commentList" class="comments">
                        <Comment
                            v-bind:key="comment.label"
                            v-for="(comment, index) in sortedComments"
                            :comment="comment"
                            :resolve-comment="() => resolveComment(comment)"
                            :edit="false"
                            :index="index"
                        />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Fab from "../../../../common/components/Fab.vue";
import Icon from "../../../../common/Icon.vue";
import Comment from "./../Comment.vue";
import thumbsUp from "../../../../../images/review/emojis/thumbs-up.png";
import heart from "../../../../../images/review/emojis/heart.png";
import clap from "../../../../../images/review/emojis/clap.png";
import bulb from "../../../../../images/review/emojis/light-bulb.png";
import checkMark from "../../../../../images/review/emojis/check-mark.png";
import ApiHelper from "@/scripts/api/ApiHelper.js";
import {ReviewCommentsFilterEnum} from "@/scripts/common/enums.js";

export default {
    name: "ReceiverFabs",
    props:['section', 'hovering', 'invertedIndex'],
    components: {
        Fab,
        Icon,
        Comment
    },
    data() {
        return {
            comments:false,
            show:false,
            listenerMounted:false,
            reactions: [
                {name:'like', value:'&#128077', img:thumbsUp},
                {name:'heart', value:'&#128150', img:heart},
                {name:'clap', value:'&#128079', img:clap},
                {name:'bulb', value:'&#128161', img:bulb},
                {name:'check', value:'&#x2705', img:checkMark},
            ],
            sectionReaction: {
                sectionId:null,
                reaction:null,
                comments:[],
                pageData:{
                    shortId:null,
                    name:null
                }
            },
        }
    },
    mounted() {
        this.emitter.on('open-comment', (sectionId) => {
            this.comments = sectionId === this.section._id;
        });
    },
    methods: {
        showComments() {
            this.comments = !this.comments;
            this.updating = null;
            this.mountOutsideClick();
        },
        mountOutsideClick() {
            if(this.comments && !this.listenerMounted) {
                this.listenerMounted = true;
                document.addEventListener('click', this.clickOutside, true);
            } else {
                this.listenerMounted = false;
                document.removeEventListener('click', this.clickOutside, true);
            }
        },
        clickOutside() {
            if (this.comments && this.$refs.comments && !this.$refs.comments.contains(event.target)) {
                this.showComments();
            }

        },
        getTooltip(reaction, reviewers) {
            return reviewers.join(", ") + " reacted with :" + this.reactions.find(x => x.value === reaction).name + ":"
        },
        getReactionImage(reaction) {
            return this.reactions.find(x => x.value === reaction).img;
        },
        async resolveComment(comment) {
            const review = this.$reviewStore.reviews.find(x => x.reviewerDetails._id === comment.fromUserId);

            const section = review.sectionReactions.find(section =>
                section.sectionId === this.section._id
            );

            if (section) {
                const targetComment = section.comments.find(x => x._id === comment._id);
                if (targetComment) {
                    targetComment.resolved = !targetComment.resolved;
                }
            }

            await ApiHelper.post(`/api/review/${review._id}/${review.contentId}`, review);

        },
    },
    computed: {
        sectionReactions() {
            let allReactions = {};
            //This monster
            this.$reviewStore.reviews.forEach(review => {
                review.sectionReactions.forEach(reactionGroup => {
                    if(reactionGroup.sectionId === this.section._id && reactionGroup.reaction != null) {
                        if(allReactions.hasOwnProperty(reactionGroup.reaction)) {
                            allReactions[reactionGroup.reaction].number += 1
                            allReactions[reactionGroup.reaction].reviewers.push(review.reviewerDetails.name)
                        }
                        else {
                            allReactions[reactionGroup.reaction] = {
                                number:1,
                                reviewers:[review.reviewerDetails.name]
                            }
                        }
                    }
                })
            })

            return allReactions;
        },
        sectionComments() {
            let allComments = this.allReviewsReactions.filter(x => x.sectionId === this.section._id).map(y => y.comments).flat().filter(comment => {
                // Exclude resolved comments if showResolved is false
                const showResolvedCondition = this.$reviewStore.filters.showResolved || !comment.resolved;

                // Only include comments that match the activeFilter unless ALL_CATEGORIES is selected
                const activeFilterCondition = this.$reviewStore.filters.activeFilter === ReviewCommentsFilterEnum.ALL_CATEGORIES
                    || comment.label === this.$reviewStore.filters.activeFilter;

                // Return true only if both conditions are met
                return showResolvedCondition && activeFilterCondition;
            });
            return allComments
        },
        allReviewsReactions() {
            let allReviews = this.$reviewStore.reviews.map(review => review.sectionReactions).flat();
            return allReviews;
        },
        sortedComments() {
            return [...this.sectionComments].sort((a, b) => {
                return new Date(a.createdAt) - new Date(b.createdAt);
            });
        },
        isThereStickyHeader() {
            return this.$store.state.view.stickedHeader
        }
    }
}
</script>