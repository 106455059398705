<template>
    <div :class="[
        'grid',
        'ufo--page-hero',
        templateSettings.navigation && templateSettings.navigation.fill === true ? 'filled' : 'transparent',
        portfolio.image.displayMode ? portfolio.image.displayMode : 'theme-default']"
    >
        <ToggleGrid/>

        <GetHeroNavigation v-if="navigationIsAbove()" :show-home-button="true" />

        <TemplateHeroBackground v-if="displayHero" :section="templateSettings.hero" />

        <template v-if="displayHero">
            <div :class="['ufo--page-hero-content',
                        !portfolio.templateSettings.hero || (portfolio.templateSettings.hero && !portfolio.templateSettings.hero.height) ? 'legacy' : '',
                        templateSettings.showProfilePicture ? 'has-picture' : '',
                        textBoxPosition]"
                        :style="[{'height': templateSettings.hero?.height ? templateSettings.hero.height + 'px' : null}, {'--rowSize': templateSettings.hero?.height ? templateSettings.hero.height + 'px' : null}, textColumnSpan]"
            >

                <div class="ufo--page-hero-columns">
                    <div v-for="element in elements"
                         :class="['col', `col-${element.key}`, verticalAlignment, horizontalAlignment, { 'full-w' : textIsOnFullWidth}]">
                        <GetHeroPortfolioImage v-if="element.key === 'media' && templateSettings.theme !== 'theme_three'"/>
                        <div v-if="element.key === 'text'"
                             :class="['ufo--page-hero-bio', 'view',
                                    !portfolio.templateSettings.hero || (portfolio.templateSettings.hero && !portfolio.templateSettings.hero.height) ? 'fix-h' : '',
                                    { 'full-w' : textIsOnFullWidth},
                                    heroAlignment]"
                        >

                            <AnimationWrapper
                                :animation-style="$store.getters.animationSettings?.animationStyle"
                                :animation-speed="$store.getters.animationSettings?.animationSpeed"
                            >
                                <template #animationContent>
                                    <div :class="['ufo--page-hero-text',
                                                   portfolio.templateSettings.hero && portfolio.templateSettings.hero.textAlign ? 'align-text-' + portfolio.templateSettings.hero.textAlign : '']"
                                    >
                                        <GetHeroPortfolioLogo v-if="templateSettings.theme === 'theme_seventeen'"/>
                                        <div class="ufo--text-area" v-html="portfolio.description"></div>
                                    </div>
                                </template>
                            </AnimationWrapper>

                            <!-- Portfolio navigation on theme: one, two -->
                            <GetHeroNavigation v-if="navigationIsInlineUnderText()" :show-home-button="false" />

                            <!-- Portfolio social links on theme: seven -->
                            <GetHeroSocialLinks v-if="socialLinksAreInline()"/>
                        </div>
                    </div>
                </div>
                <GetHeroSocialLinks v-if="socialLinksAreInHero()"/>

            </div>
            <!-- Bottom nav bar -->
            <GetHeroNavigation v-if="navigationIsBelow()" :show-home-button="true" />
        </template>
    </div>
</template>

<script>
    import GetHeroPortfolioLogo from "../common/hero/GetHeroPortfolioLogo.vue";
    import GetHeroNavigation from "../common/hero/GetHeroNavigation.vue";
    import GetHeroSocialLinks from "../common/hero/GetHeroSocialLinks.vue";
    import GetHeroPortfolioImage from "../common/hero/GetHeroPortfolioImage.vue";
    import HamburgerToggle from "../../components/HamburgerToggle.vue";
    import ToggleGrid from "../../../common/components/ToggleGrid.vue";
    import {NavigationLayoutEnum, NavigationTypeEnum, SocialLinksLocationEnum} from "../../../common/enums.js";
    import AnimationWrapper from "../../../common/components/AnimationWrapper.vue";
    import TemplateHeroBackground
        from "../../../portfolioEditor/components/themes/theme-parts/TemplateHeroBackground.vue";

    export default {
        name: "ThemeX",
        components: {
            TemplateHeroBackground,
            AnimationWrapper,
            ToggleGrid,
            HamburgerToggle,
            GetHeroPortfolioImage,
            GetHeroSocialLinks,
            GetHeroNavigation,
            GetHeroPortfolioLogo,
        },
        data() {
            return {
                hover:null,
                elements: [
                    { key: 'media'},
                    { key: 'text'}
                ]
            }
        },
        beforeMount() {
            if(!this.templateSettings.showProfilePicture) {
                this.elements.splice(0,1);
            }
            if(this.portfolio.templateSettings.hero.flipped) {
                this.elements.reverse();
            }
        },
        computed: {
            portfolio() {
                return this.$store.state.view.portfolio
            },
            project() {
                return this.$store.state.view.project
            },
            templateSettings() {
                return this.portfolio.templateSettings
            },
            displayHero() {
                //This is needed for the preview-s we use the same hero component there, but the url is always includes /p/
                //TODO: this naming can cause issues later on.
                if(window.location.pathname.includes('/templates')) {
                    return (!(window.location.pathname.includes('/about') || window.location.pathname.includes('/contact')) || this.templateSettings.theme === "theme_seventeen")
                }else {
                    return (!window.location.pathname.includes('/p/') && !window.location.pathname.includes('/templates')) || this.templateSettings.theme === "theme_seventeen"
                }
            },
            heroAlignment() {
                let classes = [];
                if(this.templateSettings.hero) {
                    if(this.portfolio.templateSettings.hero.flipped) {
                        classes.push('flipped');
                    }
                    if(this.portfolio.templateSettings.hero.alignment) {
                        if(this.portfolio.templateSettings.hero.alignment.vertical) {
                            classes.push(this.portfolio.templateSettings.hero.alignment.vertical)
                        }

                        if(this.portfolio.templateSettings.hero.alignment.horizontal) {
                            classes.push(this.portfolio.templateSettings.hero.alignment.horizontal)
                        }
                    }
                }
                return classes
            },
            textBoxPosition() {
                return this.portfolio.templateSettings.hero.textBoxPosition
                    ? `text-is-${this.portfolio.templateSettings.hero.textBoxPosition}`
                    : null
            },
            textColumnSpan() {
                return {"--text-column-span": this.portfolio.templateSettings.hero.textColumnSpan}
            },
            textIsOnFullWidth() {
                return this.$store.state.portfolio.templateSettings.hero.textColumnSpan === 12
                    && !this.$store.state.portfolio.templateSettings.showProfilePicture
            },
            verticalAlignment() {
                return this.portfolio.templateSettings?.hero?.alignment?.vertical || "middle"
            },
            horizontalAlignment() {
                return this.portfolio.templateSettings.hero?.alignment?.horizontal
            },
        },
        methods: {
            // Template layout helpers
            navigationIsAbove() {
                return this.portfolio.templateSettings.navigation.type === NavigationTypeEnum.ABOVE;
            },
            navigationIsBelow() {
                return this.portfolio.templateSettings.navigation.type === NavigationTypeEnum.BELOW;
            },
            navigationIsInlineUnderText() {
                return this.portfolio.templateSettings.navigation.type === NavigationTypeEnum.INLINE_BELOW_TEXT
            },
            socialLinksAreInline() {
                return this.portfolio.templateSettings.navigation.socialLinksLocation === SocialLinksLocationEnum.INLINE_BELOW_TEXT
            },
            socialLinksAreInHero() {
                return this.portfolio.templateSettings.navigation.socialLinksLocation === SocialLinksLocationEnum.INLINE_STICK_TO_BOTTOM
                    || this.portfolio.templateSettings.navigation.socialLinksLocation === SocialLinksLocationEnum.OFF_GRID
            },
        }
    }
</script>