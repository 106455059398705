<template>
    <div class="comments-tab">
        <template v-if="availablePages.length">
            <div v-if="!isEmpty" class="filter-container">
                <DropDownSelect :icon="'filters'"
                                :selected="activeFilter"
                                :options="filter.options"
                                @click="changeFilter($event)"
                                dark-scheme
                >
                    <template #footer-option>
                        <div class="footer-option-resolved">
                            <div>Show resolved</div>
                            <ToggleSwitch
                                :value="showResolved"
                                v-model="showResolved"
                                dark-scheme
                            />
                        </div>
                    </template>
                </DropDownSelect>
            </div>
            <div class="page-group" v-bind:key="page.shortId" v-for="page in availablePages">
                <div @click="() => openPageGroup(page.shortId)" class="page-details">
                    <div class="page-name">{{page.name}} ({{commentsLengthByPage(page.shortId).length}})</div>
                    <Icon :icon-name="openedPage === page.shortId ? 'chevron-up' : 'chevron-down'" />
                </div>
                <div class="comments-container" v-if="openedPage === page.shortId">
                    <template
                        v-for="(reaction) in commentsLengthByPage(page.shortId)"
                    >
                        <CommentComponent
                            v-if="reaction.type === 'comment'"
                            :comment="reaction"
                            :edit="false"
                            @click="() => navigateToReaction(reaction)"
                            :resolve-comment="() => resolveComment(reaction)"
                        />
                        <DisplayReaction v-else :reaction="reaction.reaction"  @click="() => navigateToReaction(reaction)"/>
                    </template>
                </div>
            </div>
        </template>
        <div v-else-if="hasResolvedComments">
            <div class="filter-container">
                <DropDownSelect :icon="'filters'"
                                :selected="activeFilter"
                                :options="filter.options"
                                @click="changeFilter($event)"
                                dark-scheme
                >
                    <template #footer-option>
                        <div class="footer-option-resolved">
                            <div>Show resolved</div>
                            <ToggleSwitch
                                :value="showResolved"
                                v-model="showResolved"
                                dark-scheme
                            />
                        </div>
                    </template>
                </DropDownSelect>
            </div>
            <ReceiverEmptyState
                :title="activeFilter === ReviewCommentsFilterEnum.ALL_CATEGORIES ? 'All comments have been resolved'  : 'There are no comments in this category'"
                :illu="EmptyCommentsIllu"
            />
        </div>
        <ReceiverEmptyState
            v-else
            :title="'The selected reviewer hasn’t added any comments'"
            :illu="EmptyCommentsIllu"
        />
    </div>
</template>

<script>
    import Icon from "../../../../common/Icon.vue"
    import CommentComponent from "../Comment.vue";
    import ReviewResultEmptyState from "@/scripts/view/review/components/ReviewResultEmptyState.vue";
    import ApiHelper from "@/scripts/api/ApiHelper.js";
    import DropDownSelect from "@/scripts/common/components/DropDownSelect.vue";
    import ToggleSwitch from "@/scripts/common/components/ToggleSwitch.vue";
    import {ReviewCommentsFilterEnum} from "@/scripts/common/enums.js";
    import DisplayReaction from "@/scripts/view/review/components/Reaction.vue";
    import ReceiverEmptyState from "@/scripts/view/review/components/ReceiverEmptyState.vue";
    import EmptyCommentsIllu from "../../../../../images/review/empty-comments.svg";


    export default {
        name: "CommentsComponent",
        props:['isEmpty'],
        components: {
            ReceiverEmptyState,
            DisplayReaction,
            ToggleSwitch,
            DropDownSelect,
            CommentComponent,
            Icon
        },
        data() {
            return {
                openedPage:'',
                filter: {
                    options: Object.values(ReviewCommentsFilterEnum)
                },
                EmptyCommentsIllu:EmptyCommentsIllu,
                ReviewCommentsFilterEnum:ReviewCommentsFilterEnum
            }
        },
        methods: {
            openPageGroup(group) {
                if(this.openedPage === group) {
                    this.openedPage = '';
                }
                else {
                    this.openedPage = group;
                }
            },
            commentsLengthByPage(page) {
                let refactoredComments = this.filteredSectionReactions.map(sectionReaction => {
                        let localComments = []
                        let fromUser = '';
                        sectionReaction.comments.map(comment => {
                            fromUser = comment.fromUserId;
                            localComments.push({...comment, page: sectionReaction.pageData.shortId, sectionData:sectionReaction.sectionId, type:'comment'})
                        })
                        if(sectionReaction.reaction && this.activeFilter === ReviewCommentsFilterEnum.ALL_CATEGORIES) {
                            localComments.push({fromUserId: fromUser, reaction:sectionReaction.reaction, page: sectionReaction.pageData.shortId, sectionData:sectionReaction.sectionId, type:'reaction'})
                        }
                        return localComments;
                    }).flat();
                return refactoredComments.filter(comment => comment.page === page);

            },
            async resolveComment(comment) {
                const section = this.$reviewStore.review.sectionReactions.find(section =>
                    section.comments.some(x => x._id === comment._id)
                );

                if (section) {
                    const targetComment = section.comments.find(x => x._id === comment._id);
                    if (targetComment) {
                        targetComment.resolved = !targetComment.resolved;
                    }
                }

                await ApiHelper.post(`/api/review/${this.$reviewStore.review._id}/${this.$reviewStore.review.contentId}`, this.$reviewStore.review);

            },
            navigateToReaction(comment) {
                let timeout = 300;
                let currentPage = window.location.pathname;
                let isHomePage =  !window.location.pathname.includes('/p/');
                let portfolioId = this.$store.state.view.portfolio.shortId
                let url = this.receiverMode ? '/myreviews/' : '/review/';

                if(!currentPage.includes(comment.page)) {
                    if(comment.page === 'home') {
                        if(!isHomePage) {
                            //home page
                            timeout = 1000;
                            this.$router.push(url + portfolioId);
                        }
                    }
                    else {
                        timeout = 1500;
                        this.$router.push(url + 'p/' + portfolioId + '/' + comment.page);
                    }
                }

                setTimeout(() => {
                    const element = document.getElementById(comment.sectionData);
                    if(!comment.reaction) {
                        this.emitter.emit("open-comment", comment.sectionData);
                    }
                    if (element) {
                        element.scrollIntoView({ behavior: 'smooth' });
                    }
                }, timeout)
            },
            changeFilter(data) {
                this.activeFilter = data;
            },
        },
        computed: {
            availablePages() {
                let uniquePageData = [];
                const uniqueIds = new Set();

                this.filteredSectionReactions.forEach(section => {
                    if (!uniqueIds.has(section.pageData.shortId) && (section.comments.length || (section.reaction && this.activeFilter === ReviewCommentsFilterEnum.ALL_CATEGORIES))) {
                        uniqueIds.add(section.pageData.shortId);
                        uniquePageData.push(section.pageData);
                    }
                });

                if(uniquePageData.length) {
                    this.openedPage = uniquePageData[0].shortId
                }
                return uniquePageData
            },
            filteredSectionReactions() {
                return this.$reviewStore.review.sectionReactions.filter( reaction => {
                    return this.availableSectionIds.includes(reaction.sectionId);
                }).map(sectionReaction => {
                    // Filter comments based on showResolved and activeFilter
                    const filteredComments = sectionReaction.comments.filter(comment => {
                        // Exclude resolved comments if showResolved is false
                        const showResolvedCondition = this.$reviewStore.filters.showResolved || !comment.resolved;

                        // Only include comments that match the activeFilter unless ALL_CATEGORIES is selected
                        const activeFilterCondition = this.$reviewStore.filters.activeFilter === ReviewCommentsFilterEnum.ALL_CATEGORIES
                            || comment.label === this.$reviewStore.filters.activeFilter;

                        // Return true only if both conditions are met
                        return showResolvedCondition && activeFilterCondition;
                    });

                    // Return the modified sectionReaction with filtered comments
                    return {
                        ...sectionReaction,
                        comments: filteredComments
                    };
                });
            },
            receiverMode() {
                return this.$reviewStore.receiver;
            },
            availableSectionIds() {
                return [...this.$store.state.view.portfolioPages.map(project => project.sections.map(section => section._id)).flat(), 'portfolio-hero', 'footer']
            },
            hasResolvedComments() {
                return this.$reviewStore.review.sectionReactions.length;
            },
            showResolved: {
                get() {
                    return this.$reviewStore.filters.showResolved;
                },
                set(newVal) {
                    this.$reviewStore.filters.showResolved = newVal;
                }
            },
            activeFilter: {
                get() {
                    return this.$reviewStore.filters.activeFilter;
                },
                set(newVal) {
                    this.$reviewStore.filters.activeFilter = newVal;
                }
            }
        }
    }
</script>

<style scoped lang="scss">
    .comments-tab {
        display: flex;
        flex-direction: column;
        height: 100%;
        overflow: auto;
        overscroll-behavior: contain;

        ::-webkit-scrollbar-track {
            background-color: transparent; /* Makes the track transparent */
        }

        scrollbar-color: #888 transparent; /* Thumb color and track color */

        .filter-container {
            display: flex;
            padding: 24px 24px 12px 24px;
            align-items: center;
            gap: 12px;
            align-self: stretch;
            width: 270px;

            .footer-option-resolved {
                display: flex;
                padding: 12px;
                justify-content: space-between;
                align-items: center;
                align-self: stretch;

                border-top: 1px solid var(--asphalt60);

                font: var(--editor-ui-b2-regular);
                color: var(--asphalt10)
            }
        }

        .page-group {
            .page-details {
                display: flex;
                padding: 20px 24px;
                justify-content: space-between;
                border-bottom: 1px solid var(--asphalt80);

                .page-name {
                    color: var(--asphalt30);
                    font: var(--editor-ui-b2-regular);
                }

                svg {
                    width: 24px;
                    height: 24px;
                    color: var(--asphalt40);
                }

                &:hover {
                    cursor: pointer;
                }
            }
        }
        .comments-container {
            border-bottom: 1px solid var(--asphalt80);
            .comment, .reaction-display-container {
                &:hover {
                    cursor: pointer;
                    background-color: var(--asphalt90);
                }
            }
        }
    }
</style>