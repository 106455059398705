import {blendColors} from "./helpers.js";

export const SectionTypesEnum = Object.freeze({
    MAIN_HEADER:"MainHeader",
    HEADER:"Header",
    TEXT:"Text",
    MEDIA:"Media",
    TEXT_AND_MEDIA:"TextAndMedia",
    NAVIGATION:"Navigation",
    STAT:"Stat",
    GALLERY:"Gallery",
    COLUMNS:"Columns",
    CONTACT_FORM:"ContactForm",
    PROJECT_GRID:"ProjectGrid",
    CTA:"Cta",
    TESTIMONIAL:"Testimonial",
    LOGO_WALL:"LogoWall",
    DIVIDER_LINE:"DividerLine"

});

export const SectionMediaTypesEnum = Object.freeze({
    IMAGE:"Image",
    VIDEO:"Video",
    PROTOTYPE:"Prototype",
    MEDIA:"Media"
});

export const SectionSuggestionTypesEnum = Object.freeze({
    BOLD_STATEMENT:"BoldStatement"
});

//Mockups
export const MockupDeviceEnum = Object.freeze({
    MOBILE:"mobile",
    TABLET:"tablet",
    DESKTOP:"desktop",
    MONITOR:"monitor"
});

export const MockupStyleEnum = Object.freeze({
    BROWSER:"browser",
    OUTLINE:"outline",
    SIMPLE:"simple",
    SEMI_REALISTIC:"semi-realistic"
});

export const MockupFeatureEnum = Object.freeze({
    SHADOW:"shadow",
    NO_SHADOW:"no-shadow",
    ROUNDED:"rounded",
    SQUARED:"squared",
    LIGHT:"light",
    DARK:"dark"
});

export const EditedImageTypeEnum = Object.freeze({
    IMAGE:"image",
    MEDIA:"media",
    GALLERY_ITEMS: "galleryItems",
    PORTFOLIO_HERO_IMAGE: "portfolioHeroImage",
    PORTFOLIO_HERO_MEDIA: "portfolioHeroMedia",
    THUMBNAIL: "thumbnail",
    THUMBNAIL_DESIGNER: 'thumbnail_designer'
});

export const DesignPanelTypesEnum = Object.freeze({
    MAIN_PANEL:"main-panel",
    TEMPLATES:"templates",
    COLORS:"colors",
    COLOR_PALETTES:"color-palettes", // New
    FONTS:"fonts", // New
    ADVANCED_HEADING_STYLE_SETTINGS: "headings", // NEW
    ADVANCED_PARAGRAPH_STYLE_SETTINGS: "paragraphs", // NEW
    ADVANCED_NAVIGATION_STYLE_SETTINGS: "navigation-buttons", // NEW
    ADVANCED_HEADING_COLOR_SETTINGS: "heading-colors",
    ADVANCED_PARAGRAPH_COLOR_SETTINGS: "paragraph-colors",
    ADVANCED_NAVIGATION_COLOR_SETTINGS: "navigation-colors",
    BUTTONS:"buttons",
    // DIVIDER_LINES:"divider-lines",
    // THUMBNAILS:"thumbnails",
    SPACING:"spacing",
    DISPLAY_OPTIONS:"display-options",
    ANIMATION_SETTINGS:"animation-settings",
    RESET:"reset"
});

export const DesignPanelPathsEnum = Object.freeze({
    ROOT:"style",
    CHANGE_TEMPLATE:"change-template",
    COLORS:"colors",
    COLOR_PALETTES:"color-palettes", // New
    FONTS:"fonts", // New
    ADVANCED_HEADING_STYLE_SETTINGS: "headings", // NEW
    ADVANCED_PARAGRAPH_STYLE_SETTINGS: "paragraphs", // NEW
    ADVANCED_NAVIGATION_STYLE_SETTINGS: "navigation-buttons", // NEW
    ADVANCED_HEADING_COLOR_SETTINGS: "heading-colors",
    ADVANCED_PARAGRAPH_COLOR_SETTINGS: "paragraph-colors",
    ADVANCED_NAVIGATION_COLOR_SETTINGS: "navigation-colors",
    BUTTON_SETTINGS:"button-settings",
    DIVIDER_LINES:"divider-line-settings",
    THUMBNAIL_LAYOUT:"thumbnail-layout",
    SPACING:"spacing"
});

export const SettingsPanelTypesEnum = Object.freeze({
    MAIN_PANEL:"main-panel",
    NAVIGATION:"navigation",
    NAVIGATION_NEW_PAGE:"add-new-page",
    NAVIGATION_EXTERNAL_LINK:"external-link",
    NAVIGATION_MAILTO_LINK:"mailto-link",
    NAVIGATION_SOCIAL_LINK:"social-link",
    SHARE:"share",
    FOOTER:"portfolio-footer",
    DOMAIN:"own-domain",
    URL:"url-slug",
    PASSWORD:"password",
    ARCHIVE:"archive",
    HIDE_FROM_SEARCH:"hide-from-search-engines",
    THUMBNAIL_SETTINGS:"thumbnail-settings",
    HEADER_FOOTER:"header-footer",
    DOMAIN_AND_URL:"domain-and-url",
    FAVICON:"favicon"
});

export const SettingsPanelPathsEnum = Object.freeze({
    ROOT:"settings",
    NAVIGATION:"portfolio-navigation",
    NAVIGATION_NEW_PAGE:"add-new-page",
    NAVIGATION_EXTERNAL_LINK:"external-link",
    NAVIGATION_MAILTO_LINK:"mailto-link",
    NAVIGATION_SOCIAL_LINK:"edit-social-link",
    SHARE:"share",
    PASSWORD:"password",
    ARCHIVE:"archive",
    THUMBNAIL_SETTINGS:"basic-details",
    HEADER_FOOTER:"display-options",
    DOMAIN_AND_URL:"domain-and-url",
    FAVICON:"favicon"
})

export const SectionSettingTypesEnum = Object.freeze({
    BACKGROUND: "background",
    CASE_STUDY_GRID: "case-study-grid",
    DIVIDER_LINES: "divider-lines",
    LOGO_WALL: "logo-wall",
    GENERAL: "general",
    COLUMNS: "columns",
    HEADER: "header",
    CTA: "cta",
    GALLERY: "gallery",
    HERO: "hero"
})

export const PageTypesEnum = Object.freeze({
    HOME:"home",
    ABOUT:"about",
    CONTACT:"contact",
    BLANK:"blank",
    LINK:"link",
    MAILTO:"mailto",
    FILE:"file",
    CASE_STUDY:"caseStudy"
});

//Didivder line
export const DividerLineTextPositionEnum = Object.freeze({
    BELOW:"below",
    ABOVE:"above",
    IN_LINE:"in_line",
    NO_TEXT:"no_text",
});
export const DividerLineWeightEnum = Object.freeze({
    LIGHT:"weight-light",
    MEDIUM:"weight-medium",
    BOLD:"weight-bold",
});

//Thumbanil layouts
export const ThumbnailLayoutsEnum = Object.freeze({
    SMALL_BLOCK:'small-block',
    SMALL_BLOCK_SLIDED:'small-block-slided',
    LARGE_BLOCK:'large-block',
    LARGE_BLOCK_MIXED:'large-block-mixed',
    SMALL_BLOCK_TEXT:'small-block-text',
    MEDIUM_BLOCK_TEXT:'medium-block-text',
});

// Navigation properties
export const NavigationTypeEnum = Object.freeze({
    ABOVE:"above", // above the hero content
    BELOW:"below", // below the hero content
    INLINE_BELOW_TEXT:"inline-below-text" // inside the hero content, under the text
})

export const ReviewTypeEnum = Object.freeze({
    PORTFOLIO:'Portfolio',
    CASE_STUDY:'CaseStudy'
})

export const NavigationLayoutEnum = Object.freeze({
    SIDES:"sides", // name or logo in the left, menu on the right
    MIDDLE:"middle", // user image in the middle, menu is split to left and right (Axis)
    SIMPLE_LEFT:"simple-left",
    SIMPLE_MIDDLE:"simple-middle",
    SIMPLE_RIGHT:"simple-right",
    LINE_MIDDLE:"line-middle"
})

export const NavigationStyleEnum = Object.freeze({
    SIMPLE:"simple",
    FORWARD_SLASH:"forward_slash"
})

export const NavigationButtonStyleEnum = Object.freeze({
    REGULAR:"regular",
    BOLD:"bold",
    UNDERLINE_REGULAR:"underline-regular",
    UNDERLINE_SMALL:"underline-small",
    UNDERLINE_LARGE:"underline-large",
    BOX_SHARP: "box-sharp",
    BOX_ROUNDED: "box-rounded",
    BOX_PILL: "box-pill",
    BOX_PILL_FILL: "box-pill-fill",
})

export const PortfolioTitleTypeEnum = Object.freeze({
    NAME:"name",
    IMAGE:"image", // eg: Axis
    LOGO:"logo"
})

export const SocialLinksLocationEnum = Object.freeze({
    NAVBAR:"in-navbar", // displaying inside the navbar where it appears
    INLINE_ABOVE_TEXT:"inline-above-text", // inside the hero or sidebar content, above the text
    INLINE_BELOW_TEXT:"inline-below-text", // inside the hero or sidebar content, below the text
    INLINE_STICK_TO_BOTTOM:"inline-stick-to-bottom", //inside the hero, Nelson template
    OFF_GRID:"off-grid" // outside of hero or navbar on a fucked up place, Poster template early versions
})

export const SocialLinkTypeEnum = Object.freeze({
    ICON:"icon",
    TEXT:"text"
})

export const SocialLinkStyleEnum = Object.freeze({
    SIMPLE:"simple",
    BOX_SHARP:"box-sharp",
    BOX_ROUNDED:"box-rounded",
    BOX_PILL:"box-pill",
    BOX_PILL_FILL:"box-pill-fill",
    UNDERLINE_REGULAR: "underline-regular"
})

export const SocialLinkWeight = Object.freeze({
    OUTLINED:"outlined",
    FILLED:"filled"
})

export const SuggestedColorsEnum = Object.freeze({
    SUBTLE:"subtle",
    PLATFORM:"platform",
    ACCENT:"accent",
    ACCENT_PLATFORM:"accent_platform",
    CONTRAST:"contrast"
})


export const ChecklistInteractionEnum = Object.freeze({
    TEMPLATE:"template",
    HEADLINE:"headline",
    CASE_STUDY:"caseStudy",
    THUMBNAIL:"thumbnail",
    LAYOUT:"layout",
    RESUME:"resume",
    DOMAIN:"domain"
})


export const ReviewStateEnum = Object.freeze({
    EMPTY:"Empty",
    INCOMPLETE:"Incomplete",
    FINISHED:"Finished",
})

export const ReviewLoginModalStateEnum = Object.freeze({
    GUEST:"guest",
    GUEST_CONTINUE:"guest_continue",
    EXISTING_USER:"existing_user",
    AUTH_ERROR:"auth_error",
    LOGIN_ERROR:"login_error",
    FOLLOW_UP:"follow_up"
})

export const ReviewCommentsFilterEnum = Object.freeze({
    ALL_CATEGORIES:"All Categories",
    DESIGN:"Design",
    CONTENT:"Content",
    STRUCTURE:"Structure",
    IMAGERy:"Imagery",
})