<template>
    <div class="empty-state" :class="[size]">
        <img rel="preload" alt="text-commnet" :src="illu" />
        <div class="text-container">
            <div class="title-text">{{ title }}</div>
            <div v-if="bodyText" class="subtitle-text">{{ bodyText }}</div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "ReceiverEmptyState",
        props: ['title', 'illu', 'bodyText', 'size'],
    }
</script>

<style scoped lang="scss">
    .empty-state {
        display: flex;
        padding: 80px 40px 40px 40px;
        flex-direction: column;
        align-items: center;
        gap: 24px;

        &.small {
            padding: 40px 80px;
            gap: 20px;

            img {
                width: 120px;
                height: 120px;
            }

            .text-container {
                .title-text {
                    font: var(--editor-ui-b2-semibold);
                }

                .subtitle-text {
                    font: var(--editor-ui-b3-regular);
                }
            }
        }

        img {
            width: 150px;
            height: 150px;
        }

        .text-container {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            gap: 8px;

            .title-text {
                text-align: center;
                color: var(--asphalt10);
                font: var(--editor-ui-b1-semibold);
            }

            .subtitle-text {
                text-align: center;
                color: var(--asphalt30);
                font: var(--editor-ui-b2-regular);
            }
        }
    }
</style>