<template>
    <slot></slot>
</template>

<script>
export default {
    name: "ClickOutsideObserver",
    emits: ['click-outside', 'close'],
    props: {
        isActive: {
            type: Boolean,
            default: false // Whether the observer should be active
        },
        ignoredClassList: {
            type: Array,
            default: []
        }
    },
    mounted() {
        if (this.isActive) {
            document.addEventListener("click", this.handleClickOutside);
        }
    },
    beforeUnmount() {
        this.cleanupObserver(); // Clean up event listeners
    },
    watch: {
        isActive(newVal) {
            if (newVal) {
                document.addEventListener("click", this.handleClickOutside);
            } else {
                this.cleanupObserver();
            }
        }
    },
    methods: {
        handleClickOutside(event) {
            const isClickInsideIgnoredElement = this.ignoredClassList.some(className =>
                event.target.closest(`.${className}`)
            );

            if (
                !this.$el.nextElementSibling.contains(event.target) &&
                !isClickInsideIgnoredElement &&
                !this.$store.state.activeModal
            ) {
                this.$emit("click-outside", event); // Emit event when clicking outside
            }
        },
        cleanupObserver() {
            document.removeEventListener("click", this.handleClickOutside);
        }
    }
};
</script>