<template>
    <div class="header-container">
        <div class="header">You already started reviewing this portfolio</div>
    </div>
    <div class="button-container">
        <CtaButton type="primary" dark-scheme @click="continueReviewAsExisting">Continue as {{ userName }}</CtaButton>
        <CtaButton type="link" dark-scheme @click="reviewAsNewGuest">Start new review</CtaButton>
    </div>
</template>

<script>
import {ReviewLoginModalStateEnum} from "@/scripts/common/enums.js";
import CtaButton from "@/scripts/common/components/CtaButton.vue";

export default {
    name: "ExistingUserLogin",
    props: ['setLoginModalState', 'getReview'],
    components: {
        CtaButton,
    },
    data() {
        return {
            ReviewLoginModalStateEnum: ReviewLoginModalStateEnum,
            content: {
                title: '',
                primaryButtonText:'',
                secondaryButtonText:''
            },
        }
    },
    methods: {
        continueReviewAsExisting() {
            //If we don't have UXFolio user it means we are cookie guest user continue the flow.
            if(!this.$reviewStore.review.reviewerDetails) {
                this.$reviewStore.review.reviewerDetails = this.user;
            }
            this.getReview();
            this.closeModal();
        },
        closeModal() {
            this.$reviewStore.showReviewLoginModal = false;
        },
        reviewAsNewGuest() {
            this.$reviewStore.loginDetails = {
                email: null,
                name: null,
                jobTitle: null
            }
            this.$cookies.remove('reviewer');
            this.setLoginModalState(ReviewLoginModalStateEnum.GUEST)
        },
    },
    computed: {
        user() {
            let guest = this.$cookies.get('reviewer');
            if(guest) {
                return guest
            }
        },
        userName() {
            return this.user ? this.user.name : 'John Doe';
        },
        userJobTitle() {
            return this.user ? this.user.jobTitle : '';
        },
    }
}
</script>

<style scoped lang="scss">
    .header-container {
        .header {
            text-align: center;
            color: var(--asphalt10);
            font: var(--editor-ui-h2)
        }
    }

    .user-wrapper {
        display: flex;
        align-items: center;
        gap: 12px;
        width: 100%;
        padding: 16px 12px;
        border-radius: 4px;
        background: var(--asphalt90);

        img {
            width: 36px;
            height: 36px;
            border-radius: 36px;
        }

        .user-details {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            gap: 2px;

            .user-name {
                font: var(--editor-ui-b2-medium);
                color: var(--asphalt20);
            }

            .user-job-title {
                font: var(--editor-ui-b3-medium);
                color: var(--asphalt40);
            }
        }
    }
</style>