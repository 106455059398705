export const heroStyleData = {
    //Tesler
    theme_eighteen: {
        height: 240,
        textAlign: "left"
    },
    //Aurora
    theme_seventeen: {
        height: 370,
        textAlign: "center",
        image: {
            height: "450",
            type: "Image",
            url: "/hero-images/template17-hero-image.png",
            width: "1440",
        },
        backgroundColor: "#F5F4FF",
        gradientColor1: "#A9C6FC",
        gradientColor2: "#A9A9FC",
        selectedBackgroundStyleId: "7",
    },
    //Norman
    theme_sixteen: {
        height: 540,
        flipped: true,
        textAlign: "left",
    },
    //Jakob
    theme_fourteen: {
        height: 645,
        textAlign: "left"
    },
    //Nelson
    theme_thirteen: {
        height: 460,
        textAlign: "left",
    },
    //Otis
    theme_twelve: {
        height: 370,
        textAlign: "left"
    },
    //Bauhaus
    theme_eleven: {
        height: 420,
        textAlign: "center"
    },
    //Orielle
    theme_ten: {
        height: 600,
        flipped: true,
        textAlign: "left",
        textColumnSpan: 6,
        image: {
            type: "Image",
            url: "/template-default-images/hero-covers/theme-10-hero-cover.webp"
        }
    },
    //Anthra
    profile_theme_nine: {
        textAlign: "center",
        alignment: {
            vertical: "top"
        },
        sidebarSettings: {
            backgroundColor: "#262f34"
        }
    },
    //Nominee
    theme_eight: {
        height: 400,
        textAlign: "center"
    },
    //Uncurled
    theme_seven: {
        height: 450,
        textAlign: "center"
    },
    //Poster
    theme_six: {
        height: 540,
        textBoxPosition: "inside-cover",
        textAlign: "center",
        alignment: {
            vertical: "bottom"
        }
    },
    //Panorama
    theme_five: {
        height: 800,
        textAlign: "left",
        image: {
            type: "Image",
            url: "/template-default-images/hero-covers/theme_05-hero-cover.webp"
        }
    },
    //Cubic
    profile_theme_four: {
        textAlign: "left"
    },
    //Axis
    theme_three: {
        height: 370,
        textAlign: "center"
    },
    //Alaine
    theme_two: {
        height: 770,
        textAlign: "left",
        textColumnSpan: 6
    },
    //Twee
    theme_one: {
        height: 380,
        textAlign: "left"
    },
    // Baze
    profile_theme_default: {
        textAlign: "left"
    },
}
