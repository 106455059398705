import {
    ReviewLoginModalStateEnum,
    ReviewTypeEnum,
    ReviewCommentsFilterEnum
} from "../../common/enums.js";

export const review = {
    namespaced: true, // Ensure namespacing is enabled
    state() {
        return {
            isReviewMode: false,
            displayUI: true,
            receiver: false,
            showReviewLoginModal: false,
            reviewLoginModalState: ReviewLoginModalStateEnum.GUEST,
            showOverviewModal: false,
            showGeneralOverview: false,
            showTour: false,
            showSubmitTooltip: false,
            emptyReview: false,
            filters: {
                showResolved:false,
                activeFilter:ReviewCommentsFilterEnum.ALL_CATEGORIES
            },
            loginDetails: {
                email: null,
                name: null,
                jobTitle: null
            },
            review: {
                overview: {
                    positiveAspects: null,
                    negativeAspects: null
                },
                sectionReactions: [],
                reviewerDetails: null,
                submitted: null
            },
            reviews: [],
            reviewType: ReviewTypeEnum.PORTFOLIO,
        };
    },
    mutations: {
        updateReview(state, review) {
            if (!(review.sectionReactions.length > 0 || review.overview.positiveAspects !== null || review.overview.negativeAspects !== null)) {
                state.emptyReview = true;
            }
            state.review = review; // Correctly update the review field
        },
    },
    getters: {
        // You can define getters here if needed
    }
};
