<template>
    <div :id="'section-' + section.index" class="ufo-sec ufo-sec--cta" :style="{'background-color:': section.backgroundColor && section.backgroundColor.length !== 8 ? section.backgroundColor : null}">
        <ToggleGrid />
        <SectionBackgroundImage :section="section"/>
        <div class="grid">
            <div :class="['ufo-sec--content', 'align-box-' + boxAlignment]">
                <AnimationWrapper
                    :animation-style="$store.getters.animationSettings ? $store.getters.animationSettings.animationStyle : null"
                    :animation-speed="$store.getters.animationSettings ? $store.getters.animationSettings.animationSpeed : null"
                >
                    <template #animationContent>
                        <div class="text-container" :class="[section.style && section.style.boxAlign ? 'align-' + section.style.boxAlign.split('-')[0] : '']">
                            <div :class="['text', section.title.style && section.title.style.textStyle ? section.title.style.textStyle : '']" v-html="fixText('title')">
                            </div>
                            <div :class="['text', section.subtitle.style && section.subtitle.style.textStyle ? section.subtitle.style.textStyle : '']" v-html="fixText('subtitle')">
                            </div>
                        </div>
                    </template>
                </AnimationWrapper>
                <CtaButton :section="section" :form-submit-button="false" :alignment="'push-to-' + boxAlignment"/>
            </div>
        </div>
    </div>
</template>

<script>
import CtaButton from "./_helper/CtaButton.vue";
import ToggleGrid from "../../../common/components/ToggleGrid.vue";
import AnimationWrapper from "../../../common/components/AnimationWrapper.vue";
import SectionBackgroundImage from "../../../sections/common/SectionBackgroundImage.vue";

export default {
    name: "ViewCta",
    components: {SectionBackgroundImage, AnimationWrapper, CtaButton, ToggleGrid},
    props:['section'],
    computed: {
        boxAlignment() {
            return this.section.style && this.section.style.boxAlign ? this.section.style.boxAlign.split('-')[0] :  "center"
        }
    },
    methods: {
        fixText(data) {
            return this.section[data].text.replaceAll('\n', '<br/>')
        }
    }
}
</script>