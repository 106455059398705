<template>
    <div class="review-result-empty-state-container">
        <p>{{ isReceiverMode ? "You reviewer hasn't added any " + title  : "You haven't added any " +  title }}</p>
        <TertiaryButton v-if="!isReceiverMode && !isSubmittedMode" @click="handleClick" dark-scheme :text="buttonText" :icon="'add'" :type="'subtle'"/>
    </div>
</template>

<script>
    import TertiaryButton from "@/scripts/common/components/TertiaryButton.vue";

    export default {
        name: "ReviewResultEmptyState",
        components: {
            TertiaryButton,
        },
        props: ['title', 'buttonText', 'handleClick'],
        computed: {
            //We are in receiver mode
            isReceiverMode() {
                return this.$reviewStore.receiver;
            },
            //We are in submitted mode
            isSubmittedMode() {
                return (this.$reviewStore.review && this.$reviewStore.review.submitted)
            }
        }
    }
</script>

<style scoped lang="scss">
    .review-result-empty-state-container {
        display: flex;
        padding: 36px 24px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 16px;
        flex: 1 0 0;
        align-self: stretch;
        height: 100%;

        border-radius: 4px;
        border: 1px dashed var(--asphalt70);
        background: rgba(255, 255, 255, 0.02);

        p {
            margin: 0;
            color: var(--asphalt40);
            text-align: center;
            font: var(--editor-ui-b3-regular);
        }
    }
</style>