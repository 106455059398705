<template>
    <modal-frame v-if="show" :show="show" @close="close" modal-size="tiny" disable-outside-click>
        <div class="review-login-modal-container">
            <component :is="currentModal" :set-login-modal-state="setLoginModalState" :get-review="getReview" />
        </div>
    </modal-frame>
</template>

<script>
import { ReviewLoginModalStateEnum } from "@/scripts/common/enums.js";
import ModalFrame from "@/scripts/common/_ModalFrame.vue";
import LoginErrorModal from "./loginModals/LoginError.vue";
import AuthErrorLoginModal from "./loginModals/AuthError.vue";
import GuestLogin from "./loginModals/GuestLogin.vue";
import ContinueLogin from "./loginModals/ContinueReview.vue";
import ExistingUserLogin from "@/scripts/view/review/modals/loginModals/ExistingUserLogin.vue";
import FollowUpLogin from "@/scripts/view/review/modals/loginModals/FollowUpLogin.vue";

export default {
    name: "ReviewLoginModalHandler",
    props: ['show', 'close', 'getReview'],
    components: {
        FollowUpLogin,
        ExistingUserLogin,
        ContinueLogin,
        GuestLogin,
        AuthErrorLoginModal,
        LoginErrorModal,
        ModalFrame,
    },
    data() {
        return {
            ReviewLoginModalStateEnum,
        };
    },
    mounted() {
        this.checkState();
    },
    methods: {
        checkState() {
            const { followup } = this.$route.query;
            const isUxolioUser = this.$store.state.user;

            //If the url includes a follow-up parameter we show the followup modal
            if(followup) {
                this.loginModalState = ReviewLoginModalStateEnum.FOLLOW_UP;
            }
            //If we have a user in the store, we have a logged in uxfolio user;
            else if(isUxolioUser) {
                this.loginModalState = ReviewLoginModalStateEnum.EXISTING_USER;
            }
            else {
                //Nothing yet
            }
        },
        setLoginModalState(state) {
            this.$reviewStore.reviewLoginModalState = state;
        },
    },
    computed: {
        currentModal() {
            switch (this.loginModalState) {
                case ReviewLoginModalStateEnum.GUEST: return GuestLogin;
                case ReviewLoginModalStateEnum.EXISTING_USER: return ExistingUserLogin;
                case ReviewLoginModalStateEnum.GUEST_CONTINUE: return ContinueLogin;
                case ReviewLoginModalStateEnum.FOLLOW_UP: return FollowUpLogin;
                case ReviewLoginModalStateEnum.LOGIN_ERROR: return LoginErrorModal;
                case ReviewLoginModalStateEnum.AUTH_ERROR: return AuthErrorLoginModal;
                default: return GuestLogin
            }
        },
        loginModalState: {
            get() {
                return this.$reviewStore.reviewLoginModalState
            },
            set(newVal) {
                this.$reviewStore.reviewLoginModalState = newVal;
            }
        }
    },
};
</script>

<style lang="scss">
    .review-login-modal-container {
        display: flex;
        padding: 32px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 32px;
        background-color: var(--asphalt95);
        border-radius: 8px;
        border: 1px solid var(--asphalt90);
        box-shadow: 0 8px 24px rgba(0, 0, 0, 0.4), 0 32px 40px rgba(0, 0, 0, 0.32);

        .header-wrapper {
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 20px;
            align-self: stretch;

            img {
                display: flex;
                width: 60px;
                height: 60px;
                justify-content: center;
                align-items: center;
                border-radius:30px;
            }

            .header-container {
                display: flex;
                flex-direction: column;
                align-items: center;
                gap: 8px;

                .header {
                    text-align: center;
                    font: var(--editor-ui-h2);
                    color: var(--asphalt10);
                }

                .subheader {
                    font: var(--editor-ui-b2-regular);
                    color: var(--asphalt30);

                    .name {
                        color: var(--asphalt10);
                        font: var(--editor-ui-b2-medium);
                    }
                }
            }
        }

        .inputs-container {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 16px;
            width: 100%;
        }

        .button-container {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            gap: 12px;
            width: 100%;

            &.row {
                flex-direction: row;

                button {
                    width: 100%;
                }
            }

            .ufo-cta--primary {
                width: 100%;
            }
        }
    }
</style>
