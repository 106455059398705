<template>
    <div class="reaction-display-container">
        <div class="reaction-wrapper">
            <div class="image-wrapper">
                <img :src="getReactionImage()" alt="reaction"/>
            </div>
        </div>
        <div class="name"><span>{{ user.name }}</span> added a reaction</div>
    </div>
</template>

<script>
import thumbsUp from "@/images/review/emojis/thumbs-up.png";
import heart from "@/images/review/emojis/heart.png";
import clap from "@/images/review/emojis/clap.png";
import bulb from "@/images/review/emojis/light-bulb.png";
import checkMark from "@/images/review/emojis/check-mark.png";

export default {
    name: "DisplayReaction",
    props: ['reaction'],
    data() {
        return {
            reactions: [
                {name:'like', value:'&#128077', img:thumbsUp},
                {name:'heart', value:'&#128150', img:heart},
                {name:'clap', value:'&#128079', img:clap},
                {name:'bulb', value:'&#128161', img:bulb},
                {name:'check', value:'&#x2705', img:checkMark},
            ],
        }
    },
    methods: {
        getReactionImage() {
            return this.reactions.find(x => x.value === this.reaction).img;
        },
    },
    computed: {
        user() {
            let owner = this.$reviewStore.receiver ? this.$store.state.user : this.$store.state.view.user

            if(this.reaction.fromUserId) {
                //We have a logged in user
                if(owner && this.reaction.fromUserId === owner._id) {
                    return {
                        name: owner.name,
                        image: owner.image
                    }
                    //If not owner find the reviewer in the reviews
                }else {
                    let reviewers = this.$reviewStore.reviews.map(x => x.reviewerDetails);
                    let foundReviewer =  reviewers.find(reviewer => reviewer._id === this.comment.fromUserId);
                    if(foundReviewer) {
                        return foundReviewer
                    }
                    //Edge case when the comment component is viewed from the admin panel, this is always true in that case
                    else {
                        return this.$reviewStore.review.reviewerDetails
                    }
                }
            }
            else {
                return this.$reviewStore.review.reviewerDetails
            }
        },
    }
}
</script>

<style scoped lang="scss">
    .reaction-display-container {
        display: flex;
        padding: 16px 20px;
        gap: 16px;
        align-self: stretch;
        align-items: center;

        &:hover {
            .reaction-wrapper {
                background-color: var(--asphalt80);
            }
        }

        .reaction-wrapper {
            display: flex;
            width: 36px;
            height: 36px;
            justify-content: center;
            align-items: center;
            gap: 10px;
            border-radius: 4px;
            background-color: var(--asphalt90);

            .image-wrapper {
                display: flex;
                width: 24px;
                height: 24px;
                justify-content: center;
                align-items: center;
                gap: 10px;
                flex-shrink: 0;

                img {
                    width: 16px;
                    height: 16px;
                    flex-shrink: 0;
                }
            }
        }

        .name {
            font: var(--editor-ui-b2-regular);
            color: var(--asphalt40);

            span {
                font: var(--editor-ui-b2-semibold);
                color: var(--asphalt10);
            }
        }
    }
</style>