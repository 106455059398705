<template>
    <div :class="['placeholder-image', size]">
        <p>{{ getUserNameInitials() }}</p>
    </div>
</template>

<script>
    export default {
        name: "UserImagePlaceholder",
        props: ['name', 'size'],
        methods: {
            getUserNameInitials() {
                let names = this.name.split(" ");
                if(names.length > 1) {
                    //Fucked up some trailing spaces so we need to check if second element not empty
                    if(names[1].length) {
                        return names[0][0] + names[1][0]
                    }
                    return names[0][0]
                }
                return names[0][0];
            }
        }
    }
</script>

<style scoped lang="scss">
    .placeholder-image {
        background-color: var(--asphalt30);
        text-align: center;
        width: 36px;
        height: 36px;
        border-radius: 36px;

        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        &.small {
            width: 36px;
            height: 36px;
        }

        &.medium {
            width: 48px;
            height: 48px;
        }

        p {
            font: var(--editor-ui-b2-semibold);
            color: var(--asphalt70);
            margin: 0;
        }
    }
</style>