<template>
    <ToggleGrid/>
    <UserName />
    <HamburgerToggle />
    <div class="profile-hero">
        <UserThemeCoverImage v-if="templateSettings.showProfilePicture" />
    </div>
    <div class="profile-bio">
        <UserPortfolioDescription />
    </div>
    <GetHeroNavigation :show-home-button="true" />
</template>

<script>
import UserName from "../../common/user/UserName.vue";
import UserThemeCoverImage from "../../common/user/UserThemeCoverImage.vue";
import UserPortfolioDescription from "../../common/user/UserPortfolioDescription.vue";
import HamburgerToggle from "../../../components/HamburgerToggle.vue";
import ToggleGrid from "../../../../common/components/ToggleGrid.vue";
import GetHeroNavigation from "../../common/hero/GetHeroNavigation.vue";

export default {
    name: "ProfileThemeNine",
    components: {
        GetHeroNavigation,
        ToggleGrid,
        HamburgerToggle,
        UserPortfolioDescription,
        UserThemeCoverImage,
        UserName
    },
    computed: {
        portfolio() {
            return this.$store.state.view.portfolio
        },
        templateSettings() {
            return this.$store.state.view.portfolio.templateSettings
        }
    }
}
</script>