<template>
    <AnimationWrapper
        :animation-style="$store.getters.animationSettings ? $store.getters.animationSettings.animationStyle : null"
        :animation-speed="$store.getters.animationSettings ? $store.getters.animationSettings.animationSpeed : null"
        :delay-animation="animationDelay"
    >
        <template #animationContent>
            <div class="text-container"
                 :class="`align-text-${sectionText.style?.textAlign || 'left'}`"
                 v-html="sectionText.text">
            </div>
        </template>
    </AnimationWrapper>
</template>

<script>
    import AnimationWrapper from "../../../../common/components/AnimationWrapper.vue";

    export default {
        name: "SectionText",
        components: {AnimationWrapper},
        props: ['sectionText', 'textIndex', 'totalColumns'],
        computed: {
            text() {
                if(this.sectionText.text.includes("rel=\"noopener noreferrer nofollow\"")) {
                    return this.sectionText.text
                }
                else {
                    return this.sectionText.text.replace("<a href=", "<a rel=\"noopener noreferrer nofollow\" href=")
                }
            },
            animationDelay() {
                if (this.textIndex || this.textIndex === 0) {
                    if(this.$store.getters.animationSettings.animationStyle === "SCALE") {
                        return 0;
                    } else if(this.$store.getters.animationSettings.animationStyle === "PAN_LEFT") {
                        return (this.totalColumns - 1 - this.textIndex) * 0.1;
                    } else {
                        return this.textIndex * 0.1;
                    }
                } else {
                    return 0;
                }
            }
        }
    }
</script>