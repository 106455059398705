<template>
    <div class="mini-fab-container" @click.stop="handleClick">
        <div :class="['icon-wrapper', type]">
            <Icon :icon-name="iconName" />
        </div>
    </div>
</template>

<script>
import Icon from "@/scripts/common/Icon.vue";
export default {
    name: "MiniFab",
    props: ['iconName', 'handleClick', 'type'],
    components: {
        Icon
    }
}
</script>

<style scoped lang="scss">
.mini-fab-container {
    position: relative;

    .icon-wrapper {
        display: flex;
        width: 28px;
        height: 28px;
        padding: 4px;
        justify-content: center;
        align-items: center;
        border-radius: 4px;

        svg {
            width: 20px;
            height: 20px;
            flex-shrink: 0;
            color: var(--asphalt10);
        }

        &.error {
            &:hover {
                background-color: var(--rose40);
            }
        }

        &:hover {
            cursor: pointer;
            background-color: var(--asphalt70);
        }
    }
}
</style>
