<template>
    <div class="ufo-sepa--head">
        <div class="head-control">

            <Cta :type="'icon back subtle no-bg'" :dark-scheme="true" v-if="showBack && !overlayView" @click="back()" >
                <Icon icon-name="back"/>
            </Cta>

            <div class="ufo-sepa--title">{{ settings.title }}</div>

            <Cta :type="'icon close subtle no-bg'" :dark-scheme="true" @click="close()">
                <Icon icon-name="close"/>
            </Cta>

        </div>
        <div v-if="settings.tabPills && !overlayView" class="head-tabs">
            <TabBar dark-scheme hide-border>
                <TabItem
                    v-for="tab in settings.tabPills"
                    :key="tab.type"
                    @click="selectTab(tab.type)"
                    :active="settings.activeTab === tab.type"
                    dark-scheme
                    :label="tab.label"
                />
            </TabBar>
        </div>
        <div v-if="showFreePlanLimitInfo" class="upgrade">
            Customization is a Premium feature! <Cta type="link" dark-scheme @click="$store.state.activeModal = 'SubscriptionSettingsModal'">Upgrade now</Cta> to save the changes you are making.
        </div>
    </div>
</template>

<script>
    import Cta from "../../components/CtaButton.vue";
    import {DesignPanelTypesEnum, SettingsPanelTypesEnum} from "../../enums.js";

    import Icon from "../../Icon.vue";
    import TabItem from "../../components/TabItem.vue";
    import TabBar from "../../components/TabBar.vue";

    export default {
        name: 'panel-header',
        props: {
            overlayView: {
                type: Boolean,
                default: false
            }
        },
        components: {TabBar, TabItem, Icon, Cta},
        data() {
            return {
                collapsed: false
            }
        },
        computed: {
            settings() {
                return this.$store.state.sidePanelSettings;
            },
            showFreePlanLimitInfo() {
                return this.$route.path.indexOf('/style') > -1 && this.$route.path.indexOf('/spacing') < 0 &&
                    this.$store.state.currentSubscriptionPlan === 'Free' &&
                    this.$store.state.sidePanelSettings.panel !== DesignPanelTypesEnum.TEMPLATES &&
                    this.$store.state.sidePanelSettings.panel !== DesignPanelTypesEnum.THUMBNAILS; //TODO: enable this for free users
            },
            showBack() {
                return !this.settings.hideBackButton
            }
        },
        methods: {
            back() {
                this.$emit('back');
            },
            close() {
                this.$emit('close');
            },
            selectTab(tabType) {
                this.$store.commit("updateSidePanel", {
                    activeTab: tabType
                })
            }
        }
    }
</script>

<style lang="scss" scoped>
    .button-icon svg {
        width: 24px;
        height: 24px;
    }

    .head-tabs {
        margin-top: -16px;
        padding: 0 30px;
    }
</style>
