<template>
    <div :id="'section-' + section.index" class="ufo-sec ufo-sec--stat">
        <ToggleGrid />
        <div class="grid">
            <div :class="['ufo--columns', 'ufo--columns-' + section.statItems.length]">
                    <div v-for="(data, index) in section.statItems" class="ufo--column" :style="{'--numberFontSize': getFontSize(data.data, 80), '--textFontSize': getFontSize(data.description, 30)}">
                        <div class="ufo--column-content">
                            <AnimationWrapper
                                :animation-style="$store.getters.animationSettings ? $store.getters.animationSettings.animationStyle : null"
                                :animation-speed="$store.getters.animationSettings ? $store.getters.animationSettings.animationSpeed : null"
                                :delay-animation="index ? index * 0.1 : 0"
                            >
                                <template #animationContent>
                                    <div :class="['number', data.data.style && data.data.style.textStyle ? data.data.style.textStyle : 'default']"
                                         :style="{'font-size': data.data.style && data.data.style.fontSize ? data.data.style.fontSize + 'px' : null}"
                                         v-html="fixText(data.data.text)"
                                    >
                                    </div>
                                </template>
                            </AnimationWrapper>
                            <AnimationWrapper
                                :animation-style="$store.getters.animationSettings ? $store.getters.animationSettings.animationStyle : null"
                                :animation-speed="$store.getters.animationSettings ? $store.getters.animationSettings.animationSpeed : null"
                                :delay-animation="index ? index * 0.1 : 0"
                            >
                                <template #animationContent>
                                    <div :class="['unit', data.description.style && data.description.style.textStyle ? data.description.style.textStyle : 'default']"
                                         :style="{'font-size': data.description.style && data.description.style.fontSize ? data.description.style.fontSize + 'px' : null }"
                                         v-html="fixText(data.description.text)"
                                    >
                                    </div>
                                </template>
                            </AnimationWrapper>
                        </div>
                    </div>
                </div>
        </div>
    </div>
</template>

<script>
import ToggleGrid from "../../../common/components/ToggleGrid.vue";
import AnimationWrapper from "../../../common/components/AnimationWrapper.vue";

export default {
    name: "Stats",
    components: {AnimationWrapper, ToggleGrid },
    props: ['section'],
    methods: {
        fixText(data) {
            return data.replaceAll('\n', '<br/>')
        },
        getFontSize(item, def) {
            return `${item.style && item.style.fontSize ? item.style.fontSize : def}px`
        }
    }
}
</script>
