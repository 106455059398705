<template>
    <div v-if="imageLayoutIsOverflow" class="fake-cut"></div>
    <div v-if="imageLayoutIsOverflow" class="fake-cut-line"></div>
    <div v-if="templateSettings.showProfilePicture && portfolio.image.url" :class="['profile-cover', 'profile-picture','ufo--page-hero-image', {'display-overflow' : imageLayoutIsOverflow}]">
        <AnimationWrapper
            :animation-style="$store.getters.animationSettings ? $store.getters.animationSettings.animationStyle : null"
            :animation-speed="$store.getters.animationSettings ? $store.getters.animationSettings.animationSpeed : null"
            :delay-animation="0.2"
        >
            <template #animationContent>
                <img v-if="portfolio.image.url" :src="portfolio.image.croppedImageUrl ? portfolio.image.croppedImageUrl : portfolio.image.url">
                <img v-else-if="user.image.url" :src="user.image.url">
                <img v-else src="../../../../../images/user-picture-empty-state.png" class="hero-image" width="1080" height="1080">
            </template>
        </AnimationWrapper>
    </div>
</template>

<script>
    import AnimationWrapper from "../../../../common/components/AnimationWrapper.vue";

    export default {
        name: "UserThemeCoverImage",
        components: {AnimationWrapper},
        computed: {
            portfolio() {
                return this.$store.state.view.portfolio
            },
            templateSettings() {
                return this.portfolio.templateSettings
            },
            imageLayoutIsOverflow() {
                return this.templateSettings.theme.includes('profile_theme_default')
                    && this.templateSettings.hero
                    && this.templateSettings.hero.sidebarSettings
                    && this.templateSettings.hero.sidebarSettings.imageLayout === 'overflow'
            },
            user() {
                return this.$store.state.view.user
            },
        }
    }
</script>