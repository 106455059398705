<template>
    <label class="switch">
        <input type="checkbox"
               :value="modelValue"
               v-model="model"
               @change="handleChange"
               :disabled="disabled"
        >
        <span :class="['slider', {dark: darkScheme}]"></span>
    </label>
</template>

<script>
    export default {
        name: 'toggle-switch',
        inheritAttrs: false,
        props: {
            modelValue: {
                type: [Boolean],
                default: null
            },
            disabled: {
                type: Boolean,
                default: false
            },
            darkScheme: {
                type: Boolean,
                default: false,
            }
        },
        computed: {
            model: {
                get() {
                    return this.modelValue
                },
                set(newValue) {
                    this.$emit('update:modelValue', newValue)
                }
            }
        },
        methods: {
            handleChange(e) {
                this.$emit('change', e);
            }
        }
    }
</script>

<style lang="scss" scoped>
    .switch {
        position: relative;
        display: inline-block;
        width: 40px;
        height: 24px;
        float: right;
        margin: 0 0 -5px 0;
        input {display:none;}
        .slider {
            position: absolute;
            cursor: pointer;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            transition: 150ms;
            border-radius: 14px;
            background-color: var(--asphalt30);
            &.dark {
                background-color: var(--asphalt70);
            }
            &:before {
                position: absolute;
                content: "";
                height: 16px;
                width: 16px;
                left: 4px;
                bottom: 4px;
                transition: 150ms;
                border-radius: 50%;
                background-color: var(--asphalt10);
                &.dark {
                    box-shadow: 0 0 4px 1px rgba(15, 15, 15, 0.2);
                }
            }
        }
        input:checked + .slider {
            background-color: var(--success60);
        }
        input:checked + .slider:before {
            transform: translateX(16px);
        }

        input:disabled + .slider {
            background-color: var(--asphalt40);
        }
    }
</style>