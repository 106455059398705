<!-- Global nav for review. It has two modes based on if reciever or review. Comes from the input variable -->
<template>
    <div class="navigation-row-container">
        <div v-if="reviewMode" class="left-side">
            <div class="main-text">
                <InlineSvg :src="logo" />
                <div>UXfolio review mode</div>
            </div>
            <div class="divider"></div>
            <div class="portfolio-text">Portfolio by <span>{{ username }}</span></div>
        </div>
        <div v-else class="left-side">
            <InlineSvg :src="logo" />
        </div>
        <div v-if="reviewMode" class="middle">
            <OptionBarGroup>
                <OptionBar
                    v-for="option in viewOptions"
                    :label="option.label"
                    :key="option.type"
                    :selected="viewMode === option.type"
                    @click="changeViewMode(option.type)"
                    :icon="option.svg"
                    :size="'small'"
                />
            </OptionBarGroup>
        </div>
        <div class="right-side">
            <!-- <ProgressButton v-if="reviewMode" :handle-click="showOverviewModal"/> -->
            <div class="button-wrapper" v-if="reviewMode">
                <ClickOutsideObserver :is-active="isActive" @click-outside="closeTooltip">
                    <CtaButton :size="'small'" :disabled="$reviewStore.review.submitted" dark-scheme type="primary" @click="showOverviewModal" >
                        {{ !$reviewStore.review.submitted ? 'Preview & send review' : 'Review submitted' }}
                    </CtaButton>
                    <LargeTooltip
                        ref="checklisttooltip"
                        :title="tooltipData.title"
                        :subtitle="tooltipData.subtitle"
                        :arrow-pos="tooltipData.arrowpos"
                        v-if="isActive"
                    />
                </ClickOutsideObserver>
            </div>
            <div class="button-container" v-else>
                <CtaButton :size="'small'" dark-scheme :type="'primary'" @click="copyLink">
                    <div class="text-with-icon">
                        <Icon :icon-name="'link'" />
                        {{ copied ? 'Copied!' : 'Copy review link' }}
                    </div>
                </CtaButton>
            </div>

        </div>
    </div>
</template>

<script>
import uxFolioLogoSymbol from "../../../../images/logo-symbol-white.svg";
import InlineSvg from "vue-inline-svg";
import ProgressButton from "./ProgressButton.vue";
import CtaButton from "../../../common/components/CtaButton.vue";
import Icon from "../../../common/Icon.vue";
import OptionBarGroup from "../../../common/sidePanels/components/OptionBarGroup.vue";
import OptionBar from "../../../common/components/OptionBar.vue"
import LargeTooltip from "@/scripts/common/components/LargeTooltip.vue";
import ClickOutsideObserver from "@/scripts/common/utilities/ClickOutsideObserver.vue";

export default {
    name: "ReviewNav",
    props: ['showOverviewModal', 'reviewMode'],
    components: {
        ClickOutsideObserver,
        LargeTooltip,
        OptionBarGroup,
        OptionBar,
        Icon,
        CtaButton,
        InlineSvg
    },
    data() {
        return {
            logo: uxFolioLogoSymbol,
            viewOptions: {
                'hide':{type:'hide', label:'View only'},
                'show':{type:'show', label:'Comment'},
            },
            viewMode:'show',
            copied:false,
            tooltipData: {
                title:"Share your feedback",
                subtitle:"When you’re ready, check and submit your review.",
                arrowpos:'top top-right'
            },
            tooltipMounted:false
        }
    },
    watch: {
        reviewSectionReactionsLength: 'mountTooltip',
        reviewOverviewPositive: 'mountTooltip',
        reviewOverviewNegative: 'mountTooltip'
    },
    methods: {
        openPortfolio() {
            try {
                let portfolioUrl = window.location.origin + "/home/portfolios/" + this.$store.state.portfolio.shortId;
                window.open(portfolioUrl, '_blank');
            } catch(e) {
                //TODO: error
            }
        },
        async copyLink() {
            try {
                let reviewUrl = window.location.origin + "/review/" + this.$store.state.portfolio.shortId;
                await navigator.clipboard.writeText(reviewUrl);
                this.copied = true;
                setTimeout(() => {
                    this.copied = false;
                }, 2000)
            } catch($e) {
                //TODO: failure
            }
        },
        changeViewMode(mode) {
            this.$reviewStore.displayUI = mode === 'show';
            this.viewMode = mode;
        },
        closeTooltip(event) {
            this.isActive = false;
        },
        mountTooltip() {
            if(this.emptyReview) {
                this.$reviewStore.emptyReview = false;
                //runs after 15 minutes
                setTimeout(() => {
                    this.isActive = true;
                    setTimeout(() => {
                        this.isActive = true;
                    }, 10000)
                }, 30000)
            }
        },
    },
    computed: {
        review() {
            return this.$reviewStore.review;
        },
        username() {
            if(this.$store.state.view.user) {
                return this.$store.state.view.user.name;
            }
            return ''
        },
        isActive: {
            get() {
                return this.$reviewStore.showSubmitTooltip;
            },
            set(newVal) {
                this.$reviewStore.showSubmitTooltip = newVal;
            }
        },
        reviewSectionReactionsLength() {
            return this.$reviewStore.review.sectionReactions.length;
        },
        reviewOverviewPositive() {
            return this.$reviewStore.review.overview.positiveAspects;
        },
        reviewOverviewNegative() {
            return this.$reviewStore.review.overview.negativeAspects;
        },
        emptyReview() {
            return this.$reviewStore.emptyReview;
        }
    }
}
</script>

<style scoped lang="scss">
    .navigation-row-container {
        height: 60px;
        padding: 12px 24px;
        width: 100%;
        justify-content: space-between;
        align-items: center;
        position: sticky;
        top: 0;

        background-color: var(--asphalt95);
        border-top: 1px solid var(--asphalt90);

        z-index: 100;
        display: flex;

        //Default font settings
        color: var(--asphalt10);

        p {
            margin:0;
        }

        .left-side {
            display: flex;
            align-items: center;
            gap: 16px;

            .main-text {
                display: flex;
                gap: 10px;
                align-items: center;


                svg {
                    width: 34px;
                    height: 24px;
                }

                div {
                    color: var(--asphalt10);
                    font: var(--review-ui-b1-medium);
                }
            }

            .divider {
                width: 1px;
                height: 24px;
                background-color: var(--asphalt80);
            }

            .portfolio-text {
                color: var(--asphalt30);
                font: var(--editor-ui-b2-regular);

                span {
                    color: var(--asphalt10);
                    font: var(--editor-ui-b2-medium);
                }
            }
        }

        .middle {
            display: flex;
            align-items: center;
            gap: 12px;
            height: 24px;
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            width: 200px;

        }

        .right-side {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-end;
            gap: 10px;

            .button-wrapper {
                position: relative;

                .large-tooltip-container {
                    top: 60px;
                    right: 30px;
                }
            }

            .button-container {
                display:flex;
                gap:12px;

                :deep(button) {
                    min-width: 160px;

                    .button-icon {
                        display: unset;
                    }
                }

                .text-with-icon {
                    display: flex;
                    flex-direction: row;
                    gap: 6px;
                    align-items: center;

                    svg {
                        width: 24px;
                        height: 24px;
                    }
                }
            }
        }
    }
</style>