<template>
    <ReceiverFabs v-if="isReceiverMode" :invertedIndex="invertedIndex" :hovering="hovering" :section="section" />
    <ReviewFabs v-else :invertedIndex="invertedIndex" :hovering="hovering" :section="section"/>
</template>

<script>
    import ReviewFabs from "./reviewer/ReviewFabs.vue";
    import ReceiverFabs from "./receiver/ReceiverFabs.vue";
    import {reviewStore} from "@/scripts/store/modules/reviewStore.js";

    export default {
        name: "FabWrapper",
        components: {
            ReceiverFabs, ReviewFabs
        },
        props:['section', 'hovering', 'invertedIndex'],
        computed: {
            isReceiverMode() {
                return this.$reviewStore.receiver || (this.$reviewStore.review && this.$reviewStore.review.submitted)
            }
        }
    }
</script>

<style lang="scss">
.review-fabs {
    position: absolute;
    right: 24px;
    top: 10px;
    z-index: 2;
    height: calc(100% - 100px);

    transition-property: transform;
    transition-timing-function: ease-in-out;
    transition-duration: 100ms;

    // Case study hero
    &.MainHeader,
    &.hero {
        top: 100px;
        height: calc(100% - 100px); // Unified for MainHeader and hero
    }

    &.sticky-header {
        .sticky {
            top: 175px;
        }
    }

    .sticky {
        display: flex;
        position: sticky;
        top: 75px;
        gap: 8px;
    }

    .ufo-fab {
        min-width: 44px;
        height: 44px;
        width: auto;
        border-radius: 6px;
        outline: 1px solid var(--asphalt90);
    }

    .reaction-fab-container {
        display: flex; /* Included from the first file */
        flex-direction: row-reverse; /* Included from the first file */
        gap: 12px; /* Included from the first file */
        position: relative; /* Included from the second file */

        .reaction-in-fab-container,
        .reaction-in-fab {
            font-size: 18px; /* Taken from the second file */
            display: flex;
            padding: 0 12px;
            justify-content: center;
            align-items: center;
            gap: 8px;

            .reaction-wrapper {
                display: flex;
                width: 20px;
                height: 20px;
                justify-content: center;
                align-items: center;
                gap: 10px;
                flex-shrink: 0;

                img {
                    width: 20px;
                    height: 20px;
                    flex-shrink: 0;
                }
            }

            span {
                color: var(--asphalt10);
                font: var(--editor-ui-b3-medium);
            }
        }

        .reactions-container {
            position: absolute;
            right: 0;
            top: 51px;
            display: flex;
            border-radius: 8px;
            border: 1px solid var(--asphalt90);
            background: var(--asphalt95);
            box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.10);

            .reaction {
                display: flex;
                width: 56px;
                height: 56px;
                padding: 6px;
                justify-content: center;
                align-items: center;

                p {
                    margin: 0;
                }

                .reaction-wrapper {
                    display: flex;
                    width: 44px;
                    height: 44px;
                    padding: 8px;
                    justify-content: center;
                    align-items: center;
                    gap: 10px;
                    flex-shrink: 0;
                    border-radius: 6px;

                    transition: transform 150ms ease-in-out;

                    &.selected {
                        background-color: var(--asphalt70);
                    }

                    &:hover {
                        background-color: var(--asphalt70);
                        cursor: pointer;
                        transform: translateY(-2px) rotate(-8deg);
                    }

                    .fab-wrapper {

                        display: flex;
                        width: 24px;
                        height: 24px;
                        justify-content: center;
                        align-items: center;
                        gap: 10px;
                        flex-shrink: 0;

                        img {
                            width: 20px;
                            height: 20px;
                            flex-shrink: 0;
                        }
                    }
                }
            }
        }
    }

    .comments-fab-container {
        position: relative;

        :deep(.ufo-fab) {
            border-radius: 6px;
        }

        .comment-in-fab {
            display: flex;
            padding: 0 12px;
            justify-content: center;
            align-items: center;
            gap: 8px;

            span {
                color: var(--asphalt10);
                font: var(--editor-ui-b3-medium);
            }

            .placeholder-image {
                display: flex;
                width: 28px;
                height: 28px;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                border-radius: 36px;
                background: var(--asphalt30);

                p {
                    margin: 0;
                    color: var(--asphalt70);
                    font: var(--editor-ui-l-semibold);
                }
            }
        }

        .comments-container {
            position: absolute;
            right: 0;
            top: 51px;

            display: flex;
            width: 380px;
            flex-direction: column;
            align-items: center;

            border-radius: 8px;
            border: 1px solid var(--asphalt90);
            background: var(--asphalt95);
            box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.10);
            z-index: 3;

            .header-row {
                display: flex;
                border-bottom: 1px solid var(--asphalt90);
                width: 100%;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                padding: 16px 20px;

                svg {
                    width: 20px;
                    height: 20px;

                    &:hover {
                        cursor: pointer;
                    }
                }

                font: var(--editor-ui-b1-semibold);
                color: var(--asphalt10);
            }

            .comments {
                display: flex;
                max-height: 360px;
                flex-direction: column;
                align-items: center;
                align-self: stretch;
                border-bottom: 1px solid var(--asphalt90);
                overflow: auto;
                overscroll-behavior: contain;

                ::-webkit-scrollbar-track {
                    background-color: transparent; /* Makes the track transparent */
                }

                scrollbar-color: #888 transparent; /* Thumb color and track color */
            }
        }
    }
}

</style>