<template>
    <div class="continue-review">
        <div class="header-row">
            <div class="title-text">Email not found</div>
            <div class="subtitle-text">We couldn’t find a review using this email address. Start a new review now!</div>
        </div>
        <div class="button-container row">
            <CtaButton type="secondary" dark-scheme @click="() => setLoginModalState(ReviewLoginModalStateEnum.GUEST_CONTINUE)">Try another email</CtaButton>
            <CtaButton type="primary" dark-scheme @click="() => setLoginModalState(ReviewLoginModalStateEnum.GUEST)">Start a new review</CtaButton>
        </div>
    </div>
</template>

<script>
    import CtaButton from "../../../../common/components/CtaButton.vue";
    import {ReviewLoginModalStateEnum} from "@/scripts/common/enums.js";

    export default {
        name: "LoginErrorModal",
        props:[ 'setLoginModalState' ],
        data() {
            return {
                ReviewLoginModalStateEnum:ReviewLoginModalStateEnum
            }
        },
        components: {
            CtaButton,
        },
    }
</script>

<style scoped lang="scss">
    .continue-review {
        display: flex;
        gap: 32px;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        .header-row {
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 16px;

            .title-text {
                font: var(--editor-ui-h2);
                color: var(--asphalt10);
            }

            .subtitle-text {
                text-align: center;
                font: var(--editor-ui-b1-regular);
                color: var(--asphalt30);
            }
        }
    }
</style>