<template>
    <template v-if="galleryLayout === 'slider'">
        <div v-if="galleryItem.media.frame && (galleryItem.media.frame === 'mobile' || galleryItem.media.frame === 'tablet' || galleryItem.media.frame === 'desktop')"
             :class="['gallery-item', 'mockup--' + galleryItem.media.frame, 'slider-mockup']"
        >
            <div class="mockup-display">
                <div :class="['mockup-image', galleryItem.media.frame === 'no-mockup' ? 'slider-image-container' : '']">
                    <img ref="img"
                         @load="imageLoaded ? imageLoaded($refs.img) : null"
                         :data-zoomable="setZoom"
                         :class="galleryItem.media.frame === 'no-mockup' ? 'slider-image' : ''"
                         loading="lazy"
                         :src="galleryItem.media.croppedImageUrl ? galleryItem.media.croppedImageUrl : galleryItem.media.url"
                         :alt="galleryItem.media.altTag ? altTagReplace(galleryItem.media.altTag) : 'Screen design displayed in a mockup'"
                         :width="galleryItem.media.width ? galleryItem.media.width : null"
                         :height="galleryItem.media.height ? galleryItem.media.height : null"
                    >
                </div>
            </div>
            <div v-if="galleryItem.media.frame.includes('desktop')" class="desktop-body"></div>
        </div>
        <div v-else :class="['gallery-item', galleryItem.media.frame ? 'mockup--' + galleryItem.media.frame : '', 'slider-mockup']">
            <div class="ufo-mockup--display">
                <div class="ufo-mockup--screen">
                    <div :class="['ufo-mockup--scroller', !galleryItem.media.frame || (galleryItem.media.frame && galleryItem.media.frame) === 'no-mockup' ? 'slider-image-container' : '']">
                        <img ref="img"
                             @load="imageLoaded ? imageLoaded($refs.img) : null"
                             :data-zoomable="setZoom"
                             :class="[!galleryItem.media.frame || (galleryItem.media.frame && galleryItem.media.frame === 'no-mockup') ? 'slider-image' : '', !galleryItem.media.frame || galleryItem.media?.frame === 'no-mockup' && forcedAspectRatio ? `aspect-ratio-${forcedAspectRatio}` : '']"
                             loading="lazy"
                             :src="galleryItem.media.croppedImageUrl ? galleryItem.media.croppedImageUrl : galleryItem.media.url"
                             :alt="galleryItem.media.altTag ? galleryItem.media.altTag : 'Screen design displayed in a mockup'"
                             :width="galleryItem.media.width ? galleryItem.media.width : null"
                             :height="galleryItem.media.height ? galleryItem.media.height : null" />
                    </div>
                </div>
                <div v-if="galleryItem.media.frame && galleryItem.media.frame !== 'no-mockup'" class="ufo-mockup--frame">
                    <img :src="'/mockups/mockup--' + galleryItem.media.frame.replace('--no-shadow', '').replace('--shadow', '') + '.svg'">
                </div>
            </div>
        </div>
    </template>
    <template v-else>

        <AnimationWrapper
            v-if="galleryLayout !== 'carousel'"
            :animation-style="$store.getters.animationSettings ? $store.getters.animationSettings.animationStyle : null"
            :animation-speed="$store.getters.animationSettings ? $store.getters.animationSettings.animationSpeed : null"
            :delay-animation="index ? index * 0.1 : 0"
        >
            <template #animationContent>
                <template v-if="galleryItem.media.frame && galleryItem.media.frame !== 'no-mockup'">
                    <div v-if="galleryItem.media.frame === 'mobile' || galleryItem.media.frame === 'tablet' || galleryItem.media.frame === 'desktop'" :class="'gallery-item mockup--' + galleryItem.media.frame">
                        <div class="mockup-display">
                            <div class="mockup-image">
                                <img ref="img"
                                     @load="imageLoaded ? imageLoaded($refs.img) : null"
                                     :data-zoomable="setZoom"
                                     loading="lazy"
                                     :src="galleryItem.media.croppedImageUrl ? galleryItem.media.croppedImageUrl : galleryItem.media.url"
                                     :alt="galleryItem.media.altTag ? galleryItem.media.altTag : 'Screen design displayed in a mockup'"
                                     :width="galleryItem.media.width ? galleryItem.media.width : null"
                                     :height="galleryItem.media.height ? galleryItem.media.height : null" />
                            </div>
                        </div>
                        <div v-if="galleryItem.media.frame.includes('desktop')" class="desktop-body"></div>
                    </div>
                    <div v-else :class="'gallery-item mockup--' + galleryItem.media.frame">
                        <div class="ufo-mockup--display">
                            <div class="ufo-mockup--screen">
                                <div class="ufo-mockup--scroller">
                                    <img ref="img"
                                         @load="imageLoaded ? imageLoaded($refs.img) : null"
                                         :data-zoomable="setZoom"
                                         loading="lazy"
                                         :src="galleryItem.media.croppedImageUrl ? galleryItem.media.croppedImageUrl : galleryItem.media.url"
                                         :alt="galleryItem.media.altTag ? galleryItem.media.altTag : 'Screen design displayed in a mockup'"
                                         :width="galleryItem.media.width ? galleryItem.media.width : null"
                                         :height="galleryItem.media.height ? galleryItem.media.height : null" />
                                </div>
                            </div>
                            <div class="ufo-mockup--frame">
                                <img :src="'/mockups/mockup--' + galleryItem.media.frame.replace('--no-shadow', '').replace('--shadow', '') + '.svg'">
                            </div>
                        </div>
                    </div>
                </template>
                <div v-else class="gallery-item">
                    <div class="mockup-display">
                        <div class="mockup-image">
                            <img ref="img"
                                 @load="imageLoaded ? imageLoaded($refs.img) : null"
                                 :loading="galleryLayout !== 'tilted' ? 'lazy' : null"
                                 :data-zoomable="setZoom"
                                 :class="!galleryItem.media.frame || galleryItem.media?.frame === 'no-mockup' && forcedAspectRatio ? `aspect-ratio-${forcedAspectRatio}` : ''"
                                 :src="galleryItem.media.croppedImageUrl ? galleryItem.media.croppedImageUrl : galleryItem.media.url"
                                 :alt="galleryItem.media.altTag ? galleryItem.media.altTag : 'Screen design displayed in a mockup'"
                                 :width="galleryItem.media.width ? galleryItem.media.width : null"
                                 :height="galleryItem.media.height ? galleryItem.media.height : null" />
                        </div>
                    </div>
                </div>
            </template>
        </AnimationWrapper>

        <template v-else>
            <template v-if="galleryItem.media.frame && galleryItem.media.frame !== 'no-mockup'">
                <div v-if="galleryItem.media.frame === 'mobile' || galleryItem.media.frame === 'tablet' || galleryItem.media.frame === 'desktop'" :class="'gallery-item mockup--' + galleryItem.media.frame">
                    <div class="mockup-display">
                        <div class="mockup-image">
                            <img ref="img"
                                 @load="imageLoaded ? imageLoaded($refs.img) : null"
                                 :data-zoomable="setZoom"
                                 loading="lazy"
                                 :src="galleryItem.media.croppedImageUrl ? galleryItem.media.croppedImageUrl : galleryItem.media.url"
                                 :alt="galleryItem.media.altTag ? galleryItem.media.altTag : 'Screen design displayed in a mockup'"
                                 :width="galleryItem.media.width ? galleryItem.media.width : null"
                                 :height="galleryItem.media.height ? galleryItem.media.height : null" />
                        </div>
                    </div>
                    <div v-if="galleryItem.media.frame.includes('desktop')" class="desktop-body"></div>
                </div>
                <div v-else :class="'gallery-item mockup--' + galleryItem.media.frame">
                    <div class="ufo-mockup--display">
                        <div class="ufo-mockup--screen">
                            <div class="ufo-mockup--scroller">
                                <img ref="img"
                                     @load="imageLoaded ? imageLoaded($refs.img) : null"
                                     :data-zoomable="setZoom"
                                     loading="lazy"
                                     :src="galleryItem.media.croppedImageUrl ? galleryItem.media.croppedImageUrl : galleryItem.media.url"
                                     :alt="galleryItem.media.altTag ? galleryItem.media.altTag : 'Screen design displayed in a mockup'"
                                     :width="galleryItem.media.width ? galleryItem.media.width : null"
                                     :height="galleryItem.media.height ? galleryItem.media.height : null" />
                            </div>
                        </div>
                        <div class="ufo-mockup--frame">
                            <img :src="'/mockups/mockup--' + galleryItem.media.frame.replace('--no-shadow', '').replace('--shadow', '') + '.svg'">
                        </div>
                    </div>
                </div>
            </template>
            <div v-else class="gallery-item">
                <div class="mockup-display">
                    <div class="mockup-image">
                        <img ref="img"
                             @load="imageLoaded ? imageLoaded($refs.img) : null"
                             :loading="galleryLayout !== 'tilted' ? 'lazy' : null"
                             :data-zoomable="setZoom"
                             :class="!galleryItem.media.frame || galleryItem.media?.frame === 'no-mockup' && forcedAspectRatio ? `aspect-ratio-${forcedAspectRatio}` : ''"
                             :src="galleryItem.media.croppedImageUrl ? galleryItem.media.croppedImageUrl : galleryItem.media.url"
                             :alt="galleryItem.media.altTag ? galleryItem.media.altTag : 'Screen design displayed in a mockup'"
                             :width="galleryItem.media.width ? galleryItem.media.width : null"
                             :height="galleryItem.media.height ? galleryItem.media.height : null" />
                    </div>
                </div>
            </div>
        </template>
    </template>
</template>

<script>
    import AnimationWrapper from "../../../../../scripts/common/components/AnimationWrapper.vue";

    export default {
        name: "GetGalleryMediaContent",
        components: {AnimationWrapper},
        props:['galleryItem', 'galleryLayout', 'index', 'forcedAspectRatio'],
        methods: {
            altTagReplace(text) {
                return text.replace('\"', '&quot;')
            },
            imageLoaded(img) {
                if (this.setZoom && !!img && !!img.dataset.zoomable && img.naturalWidth > img.width) {
                    this.$store.state.zoom.attach(img);
                } else {
                    this.$store.state.zoom.detach(img);
                }
                this.$store.commit("recalculateScrollTriggers");
            }
        },
        computed: {
            setZoom() {
                return this.galleryItem.type !== 'MainHeader'
                    && this.galleryLayout !== 'tilted'
                    && (!this.galleryItem.media.displayMode || this.galleryItem.media.displayMode && this.galleryItem.media.displayMode !== 'full')
                    && (!this.galleryItem.media.frame || this.galleryItem.media.frame && this.galleryItem.media.frame === 'no-mockup')
            },
        }
    }
</script>