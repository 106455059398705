const defaultDividerLinesSettings = {
    text_position: "below",
    text_alignment: "left",
    weight: "weight-light",
    style: "H2",
}
export const dividerLineSettings = {
    //Tesler
    theme_eighteen: {
        ...defaultDividerLinesSettings,
        weight: 'weight-medium'
    },
    //Aurora
    theme_seventeen: {
        ...defaultDividerLinesSettings,
        text_position: "in_line",
        text_alignment: "center",
    },
    //Norman
    theme_sixteen: {
        ...defaultDividerLinesSettings,
    },
    //Jakob
    theme_fourteen: {
        ...defaultDividerLinesSettings,
        weight: "weight-medium",
        style: "H1",
    },
    //Nelson
    theme_thirteen: {
        ...defaultDividerLinesSettings,
        text_position: "in_line",
        text_alignment: "center",
        weight: "weight-medium",
    },
    //Otis
    theme_twelve: {
        ...defaultDividerLinesSettings,
    },
    //Bauhaus
    theme_eleven: {
        ...defaultDividerLinesSettings,
        text_position: "no_text",
        weight: "weight-medium",
        style: "H1",
    },
    //Orielle
    theme_ten: {
        ...defaultDividerLinesSettings,
        text_position: "above",
        weight: "weight-medium",
    },
    //Anthra
    profile_theme_nine: {
        ...defaultDividerLinesSettings,
        text_alignment: "center",
        weight: "weight-medium",
        style: "H3",
    },
    //Nominee
    theme_eight: {
        ...defaultDividerLinesSettings,
        text_position: "above",
        style: "H1",
    },
    //Uncurled
    theme_seven: {
        ...defaultDividerLinesSettings,
        text_position: "in_line",
        text_alignment: "center",
        style: "p1",
    },
    //Poster
    theme_six: {
        ...defaultDividerLinesSettings,
    },
    //Panorama
    theme_five: {
        ...defaultDividerLinesSettings,
        text_position: "above",
        weight: "weight-medium",
        style: "H1",
    },
    //Cubic
    profile_theme_four: {
        ...defaultDividerLinesSettings,
        weight: "weight-medium",
        style: "H3",
    },
    //Axis
    theme_three: {
        ...defaultDividerLinesSettings,
        weight: "weight-medium",
        style: "H1",
    },
    //Alaine
    theme_two: {
        ...defaultDividerLinesSettings,
        weight: "weight-medium",
        style: "H1",
    },
    //Twee
    theme_one: {
        ...defaultDividerLinesSettings,
        text_position: "no_text",
    },
    // Baze
    profile_theme_default: {
        ...defaultDividerLinesSettings,
        text_position: "above",
        style: "H3",
    },
}