<template>
    <div class="progress-button-container">
        <div v-if="showDetails && !$reviewStore.review.submitted" class="progress-details-container"  @mouseleave="showDetails = false">
            <div class="title-container">
                <div class="title-text">Review in progress</div>
                <div class="subtitle-text">Complete these steps before submitting your review</div>
            </div>
            <div class="steps">
                <div class="step" :class="{done:positiveAspectsDone}">
                    <div :class="['indicator']"><Icon v-if="positiveAspectsDone" iconName="check" /></div>
                    <p>Highlight positive aspects</p>
                </div>
                <div class="step" :class="{done:negativeAspectsDone}">
                    <div :class="['indicator']"><Icon v-if="negativeAspectsDone" iconName="check" /></div>
                    <p>Identify areas of improvements</p>
                </div>
                <div class="step" :class="{done:sectionInteractionAdded}">
                    <div :class="['indicator']"><Icon v-if="sectionInteractionAdded" iconName="check" /></div>
                    <p>Add at least one comment</p>
                </div>
            </div>
        </div>
        <button
            :class="['ufo-cta', 'ufo-cta--progress', 'small']"
            @mouseover="showDetails = true"
            :disabled="isDisabled || $reviewStore.review.submitted"
            @click="handleClick"
        >
            <template v-if="!$reviewStore.review.submitted">
                Submit review
                <Icon :icon-name="getProgressIcon" />
            </template>
            <template v-else> Review submitted</template>
        </button>
    </div>
</template>

<script>
    import Icon from "../../../common/Icon.vue";

    export default{
        name: "ProgressButton",
        props:['handleClick'],
        components: {
            Icon,
        },
        data() {
            return {
                steps: {
                    positive:false,
                    negative:false,
                    comment:false
                },
                showDetails:false,
                isDisabled:true
            }
        },
        methods: {
            //TODO: better solution
            htmlToString(text) {
                return text.replaceAll("<p>", "")
                    .replaceAll("</p>","")
                    .replaceAll("<ul>", "")
                    .replaceAll("</ul>", "")
                    .replaceAll("<li>", "")
                    .replaceAll("</li>", "")
            },
        },
        computed: {
            getProgressIcon() {
                const { review } = this.$reviewStore;
                const progressCount = [
                    this.positiveAspectsDone,
                    this.negativeAspectsDone,
                    this.sectionInteractionAdded
                ].filter(Boolean).length; // Using Boolean to filter true values directly

                this.isDisabled = progressCount < 3; // Disable unless all steps are completed

                switch (progressCount) {
                    case 0:
                        return 'progress-indicator-empty';
                    case 1:
                        return 'progress-indicator-step1';
                    case 2:
                        return 'progress-indicator-step2';
                    default:
                        return 'progress-indicator-finished';
                }
            },
            positiveAspectsDone(){
                return this.$reviewStore.review.overview.positiveAspects != null && this.htmlToString(this.$reviewStore.review.overview.positiveAspects).length >= 1
            },
            negativeAspectsDone(){
                return this.$reviewStore.review.overview.negativeAspects != null && this.htmlToString(this.$reviewStore.review.overview.negativeAspects).length >= 1
            },
            sectionInteractionAdded(){
                return this.$reviewStore.review.sectionReactions.filter(section => section.comments.length > 0).length > 0
            },
        }
    }
</script>

<style scoped lang="scss">
    @import "../../../../styles/editor/components/editor-ui-buttons";
    .progress-button-container {
        position: relative;

        .progress-details-container {
            display: flex;
            width: 380px;
            padding: 24px;
            flex-direction: column;
            gap: 24px;

            border-radius: 12px;
            border: 1px solid var(--asphalt90);
            background: var(--asphalt95);
            box-shadow: 0px 287px 80px 0px rgba(0, 0, 0, 0.00), 0px 183px 73px 0px rgba(0, 0, 0, 0.01), 0px 103px 62px 0px rgba(0, 0, 0, 0.05), 0px 46px 46px 0px rgba(0, 0, 0, 0.08), 0px 11px 25px 0px rgba(0, 0, 0, 0.09);

            position: absolute;
            top: 55px;
            right: 0;
            z-index: 100;

            .title-container {
                display: flex;
                flex-direction: column;
                gap: 4px;

                .title-text {
                    font: var(--editor-ui-b1-medium);
                    color: var(--asphalt10);
                }

                .subtitle-text {
                    font: var(--editor-ui-b3-regular);
                    color: var(--asphalt30);
                }
            }

            .steps {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                gap: 16px;

                .step {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    gap: 8px;

                    color:var(--asphalt30);

                    &.done {
                        .indicator {
                            background-color: var(--volt);
                        }

                        p {
                            color: var(--asphalt40);
                            text-decoration: line-through;
                        }
                    }

                    .indicator {
                        width: 20px;
                        height: 20px;
                        border-radius: 24px;
                        border: 1px solid var(--asphalt60);
                        display: flex;
                        justify-content: center;
                        align-items: center;

                        svg {
                            width: 20px;
                            height: 20px;
                            color: var(--asphalt100);
                        }
                    }

                    p {
                        margin: 0;
                        font: var(--editor-ui-b3-regular);
                        color: var(--asphalt10);
                    }
                }
            }
        }

        button {
            display: flex;
            align-content: center;
            align-items: center;
            gap: 10px;

            svg {
                width: 20px;
                height: 20px;
            }
        }
    }
</style>