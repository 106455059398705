<template>
    <AnimationWrapper
        :animation-style="$store.getters.animationSettings ? $store.getters.animationSettings.animationStyle : null"
        :animation-speed="$store.getters.animationSettings ? $store.getters.animationSettings.animationSpeed : null"
    >
        <template #animationContent>
            <div v-if="caption && caption.length && caption !== '<p></p>'" class="media-caption">
                <div class="text-container" v-html="captionText"></div>
            </div>
        </template>
    </AnimationWrapper>
</template>

<script>
    import AnimationWrapper from "../../../../common/components/AnimationWrapper.vue";

    export default {
        name: "GetMediaCaption",
        components: { AnimationWrapper },
        props: ['caption'],
        computed: {
            captionText() {
                if(this.caption.includes("rel=\"noopener noreferrer nofollow\"")) {
                    return this.caption
                }
                else {
                    return this.caption.replace("\n", "<br />").replace("<a href=", "<a rel=\"noopener noreferrer nofollow\" href=")
                }
            }
        }
    }
</script>